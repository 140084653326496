import IconComillaApertura from '../../assets/iconComillasInicio.svg';
import IconComillaCierre from '../../assets/iconComillasCierre.svg';
import ImgGrupoNewsan from '../../assets/imgBackgroundGrupoNewsan.svg';
import ImgExoArgentina from '../../assets/imgBackgroudExo.svg';
import ImgRedBullArgentina from '../../assets/imgBackgroundRedBull.svg';
import ImgGpsFarma from '../../assets/gps_opinion.svg';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);
 
    const items = [
        {
            owner : "Javier Massone,",
            client : store ? "Newsan Group." : "Grupo Newsan.",
            text : store ? "Upon entering Grupo Newsan, the IURCO guys achieved such confidence that in less than six months of work we embarked on the launch of five stores in six months." :
                 "Al ingresar a Grupo Newsan, los chicos de IURCO lograron tal confianza que en menos de seis meses de trabajo nos embarcamos en el lanzamiento de cinco tiendas en seis meses.",
            since : store ? "Together since 2017" : "Juntos desde 2017",
            ImgBackground : ImgGrupoNewsan 
        },
        {
            owner : "Pablo Rossi,",
            client : "Exo Argentina.",
            text : store ? "There are many software development agencies in the market, but IURCO has something that sets it apart from the rest: they will never leave an urgency unresolved." : 
                "En el mercado hay muchas agencias de desarrollo de software, pero IURCO tiene algo que la diferencia del resto sobremanera: jamás van a dejar una urgencia sin resolver.",
            since : store ? "Together since 2012" : "Juntos desde 2012",
            ImgBackground : ImgExoArgentina
        },
        {
            owner : "María Eugenia Aramburu,",
            client : "Red Bull Argentina.",
            text : store ? "We worked hand in hand with the IURCO team to launch very important events for the region and we never had a single problem." : "Con el equipo de IURCO trabajamos codo a codo en el lanzamiento de eventos muy importantes para la región y nunca tuvimos un solo inconveniente.",
            since : store ? "Together since 2010" : "Juntos desde 2010",
            ImgBackground : ImgRedBullArgentina
        },
        {
            owner : "Emiliano Josfal,",
            client : store ? "South Drugstore (GPS Farma)" : "Drogueria del Sud (GPS Farma)",
            text : store ? "We have had problems with various agencies, but luckily with IURCO we found a strategic partner with whom we were able to really build a TEAM. Today we are proud to work with them." :
                "Hemos tenido inconvenientes con diversas agencias, pero por suerte con IURCO encontramos un partner estratégico con el cuál pudimos realmente armar un EQUIPO. Hoy estamos orgullosos de trabajar con ellos.",
            since : store ? "Together since 2020" : "Juntos desde 2020",
            ImgBackground : ImgGpsFarma 
        }
    ]
    
    const title = store ? "What do our customers say" : "Qué dicen nuestros clientes";
    
    const openIcon = IconComillaApertura;
    
    const closeIcon = IconComillaCierre;

    return {
       title,
       items,
       openIcon,
       closeIcon
    };
};
