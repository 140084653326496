import { GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import styles from './ubication.module.css';

const Ubication = () => {
    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const center = {
        lat: -34.9407324,
        lng: -58.6175243
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyDA5BcBSsKFt1IgT-wixYnIRFkueGBuOf0">
            <div className={styles.root}>
                <div className={styles.rootBackground}>
                    <GoogleMap
                        mapContainerStyle= {containerStyle}
                        center={center}
                        zoom={15}
                    >
                        <Marker position={center}/>
                    </GoogleMap>
                </div>
            </div>
        </LoadScript>
    )
}

export default Ubication