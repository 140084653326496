import { useContext } from 'react';
import { StoreContext } from '../Store/storeProvider';
import imgButton from '../../assets/imgPacks.svg';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);

    const title =  store ? "Monthly packs": "Packs mensuales";

    const iconButton = imgButton;

    const items = [
        {
            number: ".01",
            width: "20%",
            title: "usability testing",
            text:  store ? "We recommend testing and validation at different stages before, during and after the design process where it’s key that we understand our user’s needs and behavior."
                : "Recomendamos testings y validaciones tanto en el antes, durante y después del proceso de diseño. Mensualmente los análisis continúan para mejorar la Usabilidad y la Conversión."
        },
        {
            number: ".02",
            width: "20%",
            title: "search engine optimizacion",
            text:  store ? "Everybody does SEO to a degree, but only 3% do it well; that is what it takes to rank at the top of Google search. To be part of that 3% needs a holistic approach that tackles UX, Design, Content and Development." 
                : "Solamente el 3% hace SEO bien. Esos son los que están en el Top Ten de Google search. Para estar en ese grupo se necesita un trabajo en conjunto de UX, Diseño, Contenido y Desarrollo."
        },
        {
            number: ".03",
            width: "20%",
            title: "security test",
            text:  store ? "Security is one of the most important aspects of any digital online platform and MediaMonks recommends to always perform a security test before go live." 
                : "La seguridad de una aplicación es uno de los aspectos más importantes de una plataforma digital. IURCO recomienda siempre estar atentos y cumplir con los más altos estándares."
        },
        {
            number: ".04",
            width: "10%",
            title: "platform performance auditing",
            text:  store ? "We offer different packages to evaluate the performance of a platform from a usability and page load perspective. All performance scans will result in a full analysis of a web platform." 
                : "Ofrecemos una gran variedad de paquetes para evaluar la perfomance de una plataformas. Todo estos análisis arrojaran un resultado que ayudarán a mejorar sustancialmente."
        },
        {
            number: ".05",
            width: "20%",
            title: "automated quality assurance",
            text:  store ? "If testing consumes a large percentage of a Quality Assurance (QA) team's resources then this process might be a good candidate for automation." 
                : "Si los tests consumen un alto porcentaje del trabajo del equipo de QA, entonces es hora de poder implementar automatizaciones que permitan aumentar la efectividad."
        },
        {
            number: ".06",
            width: "15%",
            title: store ? "stress test" : "backend administrator",
            text:  store ? "The aim of performing a stress test is to determine the maximum capacity of the platform. The maximum number of concurrent users, pages per second and associated response times are measured." 
                : "Con nuestros especialistas en gestión de plataformas podemos garantizar la correcta administración de la aplicación. Además podrán sugerir mejoras o cambios para facilitar los procesos."
        },
        {
            number: ".07",
            width: "20%",
            title: store ? "support & maintenance" : "support & delivery",
            text:  store ? "This is all about supporting, maintaining, optimising and updating the product/service.  Depending on the needs support can be delivered either during business hours, event based, or 24/7." 
                : "Esto es fácil: support, maintenance, optimización y updating del producto/servicio.  Dependiendo de las necesidades del Cliente podemos proveer este servicio en horario laboral, basado en eventos o 24/7."
        },
        {
            number: ".08",
            width: "10%",
            title: "hosting",
            text:  store ? "Our hosting team is very experienced in setting up MediaMonks tailored environments suited to the Client's needs and will never lose sight of its 24/7 responsibilities to the platform." 
                : "Nuestro equipo tiene una gran experiencia en setear servidores de alta disponibilidad que se adapten a las necesidades del Cliente. Soportar grandes cantidades de Usuarios Concurrentes es nuestra especialidad."
        }
    ];

    const itemsRowTop = [
        {
            number: ".01",
            id: 5,
            width: "20%",
            title: "usability testing",
            text:  store ? "We recommend testing and validation at different stages before, during and after the design process where it’s key that we understand our user’s needs and behavior."
                : "Recomendamos testings y validaciones tanto en el antes, durante y después del proceso de diseño. Mensualmente los análisis continúan para mejorar la Usabilidad y la Conversión."
        },
        {
            number: ".02",
            width: "30%",
            id: 6,
            title: "search engine optimizacion",
            text:  store ? "Everybody does SEO to a degree, but only 3% do it well; that is what it takes to rank at the top of Google search. To be part of that 3% needs a holistic approach that tackles UX, Design, Content and Development." 
                : "Solamente el 3% hace SEO bien. Esos son los que están en el Top Ten de Google search. Para estar en ese grupo se necesita un trabajo en conjunto de UX, Diseño, Contenido y Desarrollo."
        },
        {
            number: ".03",
            width: "20%",
            id: 7,
            title: "security test",
            text:  store ? "Security is one of the most important aspects of any digital online platform and MediaMonks recommends to always perform a security test before go live." 
                : "La seguridad de una aplicación es uno de los aspectos más importantes de una plataforma digital. IURCO recomienda siempre estar atentos y cumplir con los más altos estándares."
        },
        {
            number: ".04",
            width: "30%",
            id: 8,
            title: "platform performance auditing",
            text:  store ? "We offer different packages to evaluate the performance of a platform from a usability and page load perspective. All performance scans will result in a full analysis of a web platform." 
                : "Ofrecemos una gran variedad de paquetes para evaluar la perfomance de una plataformas. Todo estos análisis arrojaran un resultado que ayudarán a mejorar sustancialmente."
        }
    ];

    const itemsRowBottom = [
        {
            number: ".05",
            width: "20%",
            title: "automated quality assurance",
            text:  store ? "If testing consumes a large percentage of a Quality Assurance (QA) team's resources then this process might be a good candidate for automation." 
                : "Si los tests consumen un alto porcentaje del trabajo del equipo de QA, entonces es hora de poder implementar automatizaciones que permitan aumentar la efectividad."
        },
        {
            number: ".06",
            width: "30%",
            title: store ? "stress test" : "backend administrator",
            text:  store ? "The aim of performing a stress test is to determine the maximum capacity of the platform. The maximum number of concurrent users, pages per second and associated response times are measured." 
                : "Con nuestros especialistas en gestión de plataformas podemos garantizar la correcta administración de la aplicación. Además podrán sugerir mejoras o cambios para facilitar los procesos."
        },
        {
            number: ".07",
            width: "20%",
            title: store ? "support & maintenance" : "support & delivery",
            text:  store ? "This is all about supporting, maintaining, optimising and updating the product/service.  Depending on the needs support can be delivered either during business hours, event based, or 24/7." 
                : "Esto es fácil: support, maintenance, optimización y updating del producto/servicio.  Dependiendo de las necesidades del Cliente podemos proveer este servicio en horario laboral, basado en eventos o 24/7."
        },
        {
            number: ".08",
            width: "30%",
            title: "hosting",
            text:  store ? "Our hosting team is very experienced in setting up MediaMonks tailored environments suited to the Client's needs and will never lose sight of its 24/7 responsibilities to the platform." 
                : "Nuestro equipo tiene una gran experiencia en setear servidores de alta disponibilidad que se adapten a las necesidades del Cliente. Soportar grandes cantidades de Usuarios Concurrentes es nuestra especialidad."
        }
    ]

    return {
       title,
       iconButton,
       items,
       itemsRowBottom,
       itemsRowTop
    };
};
