import React, { useEffect } from 'react';
import styles from './app.module.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HomePage from './components/HomePage/homePage';
import NewsanMagentoPage from './components/Landings/Newsan/NewsanMagento/newsanMagentoPage';
import StoreNewsanPage from './components/Landings/Newsan/NewsanStore/storeNewsanPage';
import GpsFarmaPage from './components/Landings/GpsFarma/gpsfarma';
import ExoPage from './components/Landings/Exo/exoPage';
import FelfortPage from './components/Landings/Felfort/felfortPage';
import PrismaPage from './components/Landings/Prisma/prismaPage';
import VodafonePage from './components/Landings/Vodafone/vodafonePage';
import StoreProvider from './components/Store/storeProvider';
import Ecommerce from './components/Ecommerce/ecommerce';
import NewsanMagentoPageEcommerce from './components/HomePageEcommerce/Landings/Newsan/NewsanMagento/newsanMagentoPage';
import StoreNewsanPageEcommerce from './components/HomePageEcommerce/Landings/Newsan/NewsanStore/storeNewsanPage';
import GpsFarmaPageEcommerce from './components/HomePageEcommerce/Landings/GpsFarma/gpsfarma';
import ExoPageEcommerce from './components/HomePageEcommerce/Landings/Exo/exoPage';
import FelfortPageEcommerce from './components/HomePageEcommerce/Landings/Felfort/felfortPage';
import PrismaPageEcommerce from './components/HomePageEcommerce/Landings/Prisma/prismaPage';
import VodafonePageEcommerce from './components/HomePageEcommerce/Landings/Vodafone/vodafonePage';

const App = () => {

    useEffect(() => {
        if(!localStorage.getItem('lenguage')) {
            localStorage.setItem('lenguage', JSON.stringify(false))
        }
        
        return 
    }, []);
    
    return (
        <StoreProvider>
            <div className={styles.root}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={HomePage}/>
                        <Route exact path="/newsan-magento" component={NewsanMagentoPage}/>
                        <Route exact path="/newsan-store" component={StoreNewsanPage}/>
                        <Route exact path="/gps-farma" component={GpsFarmaPage}/>
                        <Route exact path='/exo-store' component={ExoPage}/>
                        <Route exact path='/felfort' component={FelfortPage}/>
                        <Route exact path='/prisma' component={PrismaPage}/>
                        <Route exact path='/vodafone' component={VodafonePage}/>
                        <Route exact path='/ecommerce' component={Ecommerce}/>
                        <Route exact path="/ecommerce/newsan-magento" component={NewsanMagentoPageEcommerce}/>
                        <Route exact path="/ecommerce/newsan-store" component={StoreNewsanPageEcommerce}/>
                        <Route exact path="/ecommerce/gps-farma" component={GpsFarmaPageEcommerce}/>
                        <Route exact path='/ecommerce/exo-store' component={ExoPageEcommerce}/>
                        <Route exact path='/ecommerce/felfort' component={FelfortPageEcommerce}/>
                        <Route exact path='/ecommerce/prisma' component={PrismaPageEcommerce}/>
                        <Route exact path='/ecommerce/vodafone' component={VodafonePageEcommerce}/>
                    </Switch>
                </BrowserRouter> 
            </div>
        </StoreProvider>
    )
}  
  
export default App;