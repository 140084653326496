import React from 'react';
import Title from '../Title/title';
import styles from './renderHomePage.module.css';

const RenderHomePage = (props) => {
    const {
        text,
        img
    } = props;

    return (
        <div className={styles.root}>
            <Title text={text}/>
            <section className={styles.images}>
                <img 
                    alt="technology icons"  
                    src={img} 
                    className={text === 'ecommerce' ? styles.ecommerceImg : styles.img}   
                    height={800}
                    width={800}
                />
            </section>
        </div>
    )
}

export default RenderHomePage
