import LogoAtma from '../../assets/logoAtma.svg';
import LogoVodafone from '../../assets/logoVodafone.svg';
import LogoUnilever from '../../assets/logoUnilever.svg';
import LogoGpsFarma from '../../assets/logoGpsFarma2.png';
import LogoMotorola from '../../assets/logoMotorola2.png';
import LogoQuaker from '../../assets/logoQuaker.svg';
import LogoTiendaExo from '../../assets/lgooTiendaExo.svg';
import LogoNewsan from '../../assets/logoNewsan.svg';
import LogoMovistar from '../../assets/logoMovistar.svg';
import LogoNoblex from '../../assets/logoNoblex.svg';
import LogoRedBull from '../../assets/logoRedBull.svg';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);

    const data = [
        {
            name: "atma",
            img: LogoAtma
        },
        {
            name: "vodafone",
            img: LogoVodafone
        },
        {
            name: "unilever",
            img: LogoUnilever
        },
        {
            name: "gpsFarma",
            img: LogoGpsFarma
        },
        {
            name: "motorola",
            img: LogoMotorola
        },
        {
            name: "quaker",
            img: LogoQuaker
        },
        {
            name: "tiendaExo",
            img: LogoTiendaExo
        },
        {
            name: "newsan",
            img: LogoNewsan
        },
        {
            name: "movistar",
            img: LogoMovistar
        },
        {
            name: "noblex",
            img: LogoNoblex
        },
        {
            name: "redBull",
            img: LogoRedBull
        }
    ]

    const dataDesktop1 = [
        {
            name: "atma",
            img: LogoAtma
        },
        {
            name: "vodafone",
            img: LogoVodafone
        },
        {
            name: "unilever",
            img: LogoUnilever
        },
        {
            name: "gpsFarma",
            img: LogoGpsFarma
        },
        {
            name: "motorola",
            img: LogoMotorola
        },
        {
            name: "quaker",
            img: LogoQuaker
        }
    ]

    const dataDesktop2 = [
        {
            name: "tiendaExo",
            img: LogoTiendaExo
        },
        {
            name: "newsan",
            img: LogoNewsan
        },
        {
            name: "movistar",
            img: LogoMovistar
        },
        {
            name: "noblex",
            img: LogoNoblex
        },
        {
            name: "redBull",
            img: LogoRedBull
        }
    ]
    
    const title = store ? "They trust us" : "Confian en Nosotros";

    return {
       title,
       dataDesktop1,
       dataDesktop2,
       data
    };
};


