import iconLogo from '../../../../assets/logoPrismaLanding.svg';
import imgPrisma from '../../../../assets/imgMobilePrimaLanding.png';
import imgPrismaDesktop from '../../../../assets/imgDesktopPrismaLanding.png';
import iconTeam from '../../../../assets/equipo.gif';
import iconSprintDuration from '../../../../assets/duracion.gif';
import iconTechnology from '../../../../assets/magento_woo_presta.gif';
import { useContext } from 'react'; 
import { StoreContext } from '../../../Store/storeProvider';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);
 
    const technology = [
        {
            img: iconLogo,
            title: 'PRISMA',
            subtitle: store ? <><h4>E-COMMERCE |</h4><p>Module "Payment methods"</p></> : <><h4>E-COMMERCE |</h4><p>Módulo “Métodos de pago”</p></>,
            text: store ? 'At the beginning of 2021, Prisma entrusted us with the development and integration of the "Payment Methods" Module. This module, officially offered by Prisma, covered the integration for 3 e-commerce platforms: PrestaShop, Woocommerce and Magento 2. Once launched, IURCO will be in charge of the Support & Delivery of the integration on its 3 platforms. The scope of development includes everything from the payment method at checkout to the management within the different platforms of bank promotions, card promotions, payment plans, among other functionalities.' : 
                'A inicios del 2021 Prisma nos encomendó el desarrollo e integración del Módulo “Métodos de pago”. Este módulo, que ofrece de manera oficial Prisma, abarcó la integración para 3 plataformas de e-commerce: PrestaShop, Woocommerce y Magento 2. Una vez lanzado, IURCO será el encargado del Support & Delivery de la integración en sus 3 plataformas. El alcance del desarrollo contempla desde el método de pago en el checkout hasta la gestión dentro en las distintas plataformas de promociones bancarias, promociones de tarjetas, planes de pago, entre otras funcionalidades.',
        }
    ];
    
    const storePrisma = [
        {   
            img: imgPrisma,
            imgDesktop: imgPrismaDesktop,
        }
    ];
    
    const textMethodology = store ? "AS WE DO FOR EACH PROJECT, WE WORK UNDER THE SCRUM METHODOLOGY, COLLABORATIVELY AND AS A TEAM TO OBTAIN THE BEST POSSIBLE RESULT OF THE PROJECT    " :
         'COMO LO HACEMOS PARA CADA PROYECTO TRABAJAMOS BAJO LA METODOLOGÍA SCRUM, COLABORATIVAMENTE Y EN EQUIPO PARA OBTENER EL MEJOR RESULTADO POSIBLE DEL PROYECTO';
    
    const methodologyPrisma = [
        {   img:  iconTeam,
            title: store ? "TEAM": 'EQUIPO',
            description: store ? 
                [   
                    '1 designer UX / UI',
                    '3 developers',
                    '1 p.m ',
                    '1 QA '
                ] : 
                [   
                    '1 diseñadora UX / UI',
                    '3 desarrolladores',
                    '1 p.m ',
                    '1 control de calidad '
                ]
        },
        {
            img: iconSprintDuration,
            title: store ? "SPRINT DURATION" : 'DURACIÓN DEL SPRINT',
            description: store ? ["6 sprints from inception to fully response product deliver"] : 
                ["6 sprints desde el inicio hasta la entrega del producto totalmente response"]
        },
        {
            img: iconTechnology,
            title: store ? "TECHNOLOGIES" : 'TECNOLOGÍAS',
            description: store ? ["Magento, modern e-commerce platform in the cloud. Woocommerce, e-commerce solution based on Wordpress. Prestashop, open source CMS platform for the creation and maintenance of electronic commerce.            "] : 
                ['Magento, moderna plataforma de comercio electrónico en la nube. Woocommerce, solución de comercio electrónico basada en Wordpress. Prestashop, plataforma CMS de código abierto para la creación y manutención de comercio electrónico.']
        },
    ]
    
    const prisma = true;

    return {
        technology,
        storePrisma,
        textMethodology,
        methodologyPrisma,
        prisma
    };
};