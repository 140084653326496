import mobile from '../../assets/iconMobileProjectProcess.svg';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';
export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);
     
    const title =  store ? "Project Process": "Proceso del Proyecto";

    const iconMobile = mobile;

    const items = [
        {
            title: "Discovery",
            subtitle: store ? "gain strategic insights" : "ganar conocimientos estratégicos",
            text: store ? "In its users and the current panorama." : "En sus usuarios y el panorama actual."
        },
        {
            title: "Get ready",
            subtitle: store ? "define the concept" : "definir el concepto",
            text: store ? "Ensure that our vision, objectives and ideas are on track." : "Asegurar que nuestra visión, objetivos e ideas estén encaminadas."
        },
        {
            title: "Development",
            subtitle: store ? "Deliver the solution": "entregar la solución",
            text: store ? "Remain open to adapt and improve as we learn and progress." : "Permanecer abierto para adaptarse y mejorar a medida que aprendemos y progresamos."
        },
        {
            title: "Drive",
            subtitle: store ? "optimize experience": "optimizar la experiencia",
            text: store ? "Remain open to adapt and improve as we learn and progress." : 
                "Escuchar, monitorear e investigar para entregar la mejor experiencia al cliente."
        }
    ];

    return {
       title,
       iconMobile,
       items
    };
};
