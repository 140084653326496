import React,  { useRef, useState } from 'react';
import { useSampleData } from './sampleData';
import styles from './contactForm.module.css';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';
import emailjs from '@emailjs/browser';
import  ReCAPTCHA  from 'react-google-recaptcha';

const ContactForm = () => {
    const [ emailSucces, setEmailSucces ] = useState(false);
    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null);
    const [genericError, setGenericError] = useState('');
    const [ store ] = useContext(StoreContext);
    const { title, items, iconButton, textButton, emailText, genericErrorText, textButtonLoading } = useSampleData();

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setGenericError('');
        if (!nombre || !correo || !telefono || !mensaje || !captchaValue) {
            setGenericError(genericErrorText);
            return;
        }

        emailjs.sendForm('service_z8ipg7f', 'template_h6sdcky', form.current, 'LDxMJs6rEJrQhw8T-')
        .then((result) => {
            setEmailSucces(true)
        }, (error) => {
            console.log(error.text);
        });
    };

    const captcha = useRef(null);

    const handleNombreChange = (e) => {
        setNombre(e.target.value);
    };
      
    const handleCorreoChange = (e) => {
        setCorreo(e.target.value);
    };
      
    const handleTelefonoChange = (e) => {
        setTelefono(e.target.value);
    };
      
    const handleMensajeChange = (e) => {
        setMensaje(e.target.value);
    };
    
    const onChange = (value) => {
        setCaptchaValue(value);
    };

    const submitButton = genericError ? styles.submitButtonDisable : styles.submitButton ;

    return (
        <div className={styles.root}>
            <div className={styles.rootDesktop}>
                <h3 className={styles.title}>{title}</h3>
                <div className={styles.socialNetworks}>
                    {
                        items && items.map((item)=>{
                            return (
                                <div key={item.id} className={styles.socialNetwork}>
                                    <img src={item.img} alt="icon" className={styles.icon}/>
                                    <div className={styles.text}>{item.text}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <form ref={form} onSubmit={sendEmail} className={styles.form}>
                    <input name="user_name" type="text" placeholder={store ? "Name" : "Nombre"} className={styles.input} value={nombre} onChange={handleNombreChange} />
                    <input name="user_email" type="text" placeholder="Email" className={styles.input} value={correo} onChange={handleCorreoChange} />
                    <input name="user_telephone" type="text" placeholder={store ? "Phone" : "Teléfono"} className={styles.input} value={telefono} onChange={handleTelefonoChange} />
                    <input name="message" type="textarea" placeholder={store ? "Message" : "Mensaje"} className={styles.inputArea} value={mensaje} onChange={handleMensajeChange} />
                    {genericError && <p className={styles.error}>{genericError}</p>}
                    <div className={styles.recaptchaBox}>
                        <ReCAPTCHA
                            ref= {captcha}
                            sitekey= "6Le-cw0oAAAAAC79w_ovRRD5J53uSNhagu-dIpy8"
                            onChange= {onChange}
                            className={styles.recaptcha}
                        />
                    </div>
                    <button type="submit" value="Send" className={emailSucces ? styles.hideButton : submitButton}>
                        <p>{emailSucces ? textButtonLoading : textButton}</p>
                        { !emailSucces && <img src={iconButton} alt="icon" className={styles.iconButton}/>}
                    </button>
                    <p className={!emailSucces ? styles.emailSuccesText : styles.emailSuccesTextShow}>{emailText}</p>
                </form>
            </div>
        </div>
    )
}

export default ContactForm
