import React from 'react';
import styles from './footer.module.css';
import { Link } from 'react-router-dom';
import { useSampleData } from './sampleData';

const Footer = (props) => {
    const { logoIurco, links, socialMedia, ecommerceLinks } = useSampleData(props);

    const { isEcommercePage = false } = props;

    const scrollToSection = (elementRef, top) => {
        window.scrollTo({
            top: (elementRef && elementRef.current) ? elementRef.current.offsetTop : top,
            behavior: 'smooth',
        })
    };

    const scrollToSectionEcommerce = (top) => {
        window.scrollTo({
            top: top,
            behavior: 'smooth',
        })
    };
 
    return (
        <div className={styles.root}>
            <div className={styles.logoIurco}>
                <Link to="/">
                    <img alt="IURCO logo" src={logoIurco} className={styles.logo} />
                </Link>
            </div>
            {
                isEcommercePage ? 
                <div className={styles.links}>
                    { 
                        ecommerceLinks.map((item, index) => {
                            return (
                                <div key={index} className={styles.item}>
                                    <Link 
                                        onClick={()=> scrollToSectionEcommerce(item.top)} 
                                        className={styles.link}
                                    >
                                        <p className={styles.name}>
                                            {item.name}
                                        </p>
                                    </Link>
                                    <span className={(item.name === "contacto" || item.name === "contact") ? styles.spamNull : styles.spam}> | </span> 
                                </div>    
                        )})
                    }
                </div> :
                <div className={styles.links}>
                    { 
                        links.map((item, index) => {
                            return (
                                <div key={index} className={styles.item}>
                                    <Link 
                                        to="/"
                                        onClick={()=> scrollToSection(item.path, item.top)} 
                                        className={styles.link}
                                    >
                                        <p className={styles.name}>
                                            {item.name}
                                        </p>
                                    </Link>
                                    <span className={(item.name === "contacto" || item.name === "contact") ? styles.spamNull : styles.spam}> | </span> 
                                </div>    
                        )})
                    }
                </div>
            }
            <div className={styles.socialMedia}>
                {
                    socialMedia.map((item,index)=> {
                        return (
                            <a href={item.url} target="_blank" rel="noreferrer" key={index}>
                                <img 
                                    alt={item.name} 
                                    src={item.src} 
                                    className={item.name === 'instagram' ? styles.ig : styles.notClasses}
                                />
                            </a>
                        )
                    })
                }
            </div> 
        </div>
    )
}

export default Footer
