import yearsOfExperienceDesktop from '../../assets/yearsOfExperienceDesktop.png';
import fullfilledProjectsDesktop from '../../assets/fulfilledProjectsDesktop.png';
import ourTeamDesktop from '../../assets/ourTeamDesktop.png';
import yearsOfExperienceMobile from '../../assets/yearsOfExperienceMobile.png';
import fullfilledProjectsMobile from '../../assets/fullfilledProjectsMobile.png';
import ourTeamMobile from '../../assets/ourTeamMobile.png';
import openingQuoteMobile from '../../assets/openingQuoteMobile.png';
import openingQuoteDesktop from '../../assets/openingQuoteDesktop.png';
import closingQuoteMobile from '../../assets/closingQuoteMobile.png';
import closingQuoteDesktop from '../../assets/closingQuoteDesktop.png';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);
 
    const text = store ? "We do not create projects, we create relationships." : "No creamos proyectos, creamos relaciones.";

    const images = [
        { 
            mobile: ourTeamMobile,
            desktop: ourTeamDesktop,
            number: '30',
            text: store ? 'our team' : 'nuestro equipo'
        },
        { 
            mobile: yearsOfExperienceMobile,
            desktop: yearsOfExperienceDesktop ,
            number: '14',
            text: store ? 'years of experience' : 'años de experiencia'
        },
        { 
            mobile: fullfilledProjectsMobile,
            desktop: fullfilledProjectsDesktop,
            number: '158',
            text: store ? 'fulfilled projects' : 'proyectos realizados'
        }
    ];
    
    const mobileQuotesImages = {opening: openingQuoteMobile, closing: closingQuoteMobile};
    
    const desktopQuoteImages = {opening: openingQuoteDesktop, closing: closingQuoteDesktop};

    return {
       images,
       text,
       mobileQuotesImages,
       desktopQuoteImages
    };
};
