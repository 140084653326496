import logoScrum from '../../assets/logoScrumOrg.svg';
import logoMethodology1 from '../../assets/logoMethodology1.svg';
import logoMethodology2 from '../../assets/logoMethodology2.svg';
import logoMethodology3 from '../../assets/logoMethodology3.svg';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext); 
    
const items = [
    {
        description: store ? "Alignment between the client and the development team" :
             "Alineamiento entre el cliente y el equipo de desarrollo.",
        img: logoMethodology1
    },
    {
        description: store ? "Productivity and quality." : "Productividad y calidad.",
        img:logoMethodology2
    },
    {
        description: store ? "Motivated team." : "Equipo motivado.",
        img:logoMethodology3
    }
]

const title = store ? "How we work" : "Cómo trabajamos";

const titleMethodology = store ? "Methodology" : "Metodología";

const text = store ? <p>At IURCO we work under the <strong>Scrum methodology</strong>, a process in which a set of good practices are regularly applied to <strong>work collaboratively</strong>, as a <strong>team</strong>, and <strong>obtain the best possible result</strong> from a project.</p> : 
    <p>En IURCO trabajamos bajo la <strong>metodología Scrum</strong>, un proceso en el que se aplican de manera regular un conjunto de buenas prácticas para <strong>trabajar colaborativamente</strong>, en <strong>equipo</strong>, y <strong>obtener el mejor resultado posible</strong> de un proyecto.</p>;

const img= logoScrum;

    return {
       title,
       text,
       img,
       titleMethodology,
       items
    };
};
