import React from 'react';
import styles from './workTogether.module.css';
import useViewportWidth from '../../talons/ViewportWidth/useViewportWidth';
import { useSampleData } from './sampleData';

export const WorkTogether = () => {
    const { 
        title, 
        buttonText, 
        backgroundMobile, 
        backgruondDesktop, 
        titleDesktopBottom,
        titleDesktopTop 
    } = useSampleData();

    const width = useViewportWidth ();

    let image = "";

    if (width < 1500) {
        image = backgroundMobile;
    } else {
        image = backgruondDesktop;
    }

    const handleClick = () => {
        window.scrollTo({
            top: 7850,
            behavior: 'smooth',
        })
    }

    return (
        <div className={styles.root}>
            <img className={styles.image} src={image} alt="work together icon"/>
            <div className={styles.container}>
                <p className={styles.title}>{title}</p>
                <p className={styles.titleDesktop}>{titleDesktopTop}</p>
                <p className={styles.titleDesktop}>{titleDesktopBottom}</p>
                <button className={styles.button} onClick={handleClick}>
                    {buttonText}
               </button>
            </div>
        </div>
    )
}

export default WorkTogether