import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);

    const title = store ? "About us" : "Quiénes somos";

    const text = store ? 
        `We are a boutique agency, without jurassic processes and with the agility of todays world.
        In such a fast-changing world, brands need to move faster than ever.
        They need smart and integrated solutions, built with speed, scalability, 
        and, above all, quality. Our solution is simple and singular. A single agency that works
        efficient way to be able to deliver above average products.`
        : 
        `Somos una agencia boutique, sin procesos jurásicos y con la agilidad del mundo actual.
        En un mundo tan cambiante, las marcas necesitan moverse más rápido que nunca.
        Necesitan soluciones inteligentes e integradas desarrolladas con velocidad, escalabilidad y,
        sobre todo, calidad. Nuestra solución es simple y singular. Una sola agencia que trabaje de
        manera eficaz para poder entregar productos por encima de la media.`;

    return {
       title,
       text
    };
};
