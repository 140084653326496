import imgMobile from '../../assets/backgroundWorkTogetherMobile.png';
import imgDesktop from '../../assets/backgruondWorkTogetherDesktop.png';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);
 
    const title = store ? "let's work together to achieve your goals" : "trabajemos juntos en alcanzar tus metas";

    const titleDesktopTop = store ? "Let's work together" : "trabajemos juntos";

    const titleDesktopBottom = store ? "in reaching your goals" : "en alcanzar tus metas";

    const buttonText = store ? "start your project" : "empezá tu proyecto";

    const backgroundMobile = imgMobile;

    const backgruondDesktop = imgDesktop;

    return {
       title,
       buttonText,
       titleDesktopBottom,
       titleDesktopTop,
       backgroundMobile,
       backgruondDesktop
    };
};
