import logoGpsFarma from '../../assets/logoGpsFarma.svg';
import logoExo from '../../assets/logoExoStore.png';
import logoFelfort from '../../assets/logoFelfort.png';
import logoPrisma from '../../assets/logoPrisma.png';
import logoVodafone from '../../assets/logoVodafone.png';
import logoNewsan from '../../assets/logo-newsan.svg';
import logoStoreNewsan from '../../assets/logoStoreNewsan.png';
import magento2storeOnline from '../../assets/logoMagento2storeOnline.png';
import pwa from '../../assets/pwaIcon.svg';
import wordpress from '../../assets/iconWordpress.png';
import paymenthsMethods from '../../assets/iconPaymentMethods.png';
import webGpsFarma from '../../assets/webGpsFarmaMobile.png';
import webExo from '../../assets/webExoStore.png';
import webFelfort from '../../assets/webFelfort.png';
import webPrisma from '../../assets/webPrisma.png';
import webNewsan from '../../assets/imgNewsan.png';
import webStoreNewsan from '../../assets/imgStoreNewsan.png';
import webVodafone from '../../assets/imgVodafone.png';
import imgButton from '../../assets/imgButtonOurProjectsMobile.png';
import imgButtonSeeMore from '../../assets/iconButtonSeeMoreProjects.svg';
import exoDesktop from '../../assets/exoOurProjectsDesktop.png';
import felfortDesktop from '../../assets/felfortOurProjectsDesktop.png';
import gpsFarmaDesktop from '../../assets/gpsOurProjectsDesktop.png';
import prismaDesktop from '../../assets/prismaOurProjectsDesktop.png';
import vodafoneDesktop from '../../assets/vodafoneOurProjectsDesktop.png';
import storeNewsanDesktop from '../../assets/storeNewsanOurProjectsDesktop.png';
import newsanDesktop from '../../assets/newsanOurProjectsDesktop.png';
import exoHover from '../../assets/exoHover.png';
import felfortHover from '../../assets/felfortHover.png';
import gpsHover from '../../assets/gpsHover.png';
import prismaHover from '../../assets/prismaHover.png';
import vodafoneHover from '../../assets/vodafoneHover.png';
import storeNewsanHover from '../../assets/storeNewsanHover.png';
import newsanHover from '../../assets/newsanHover.png';
import imgDesktop from '../../assets/imgOurProjectsDesktop.svg';
import { useContext } from 'react';
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = (props) => {
    const [store] = useContext(StoreContext);

    const title = store
        ? 'A few of our projects'
        : 'Algunos de nuestros Proyectos';

    const textButton = store ? 'See Project' : 'Ver Proyecto';

    const textButtonSeeMoreProjects = store
        ? 'SEE MORE PROJECTS'
        : 'VER MÁS PROYECTOS';

    const textButtonSeeLessProjects = store
        ? 'SEE LESS PROJECTS'
        : 'VER MENOS PROYECTOS';

    const iconButton = imgButton;

    const iconButtonSeeMoreProjects = imgButtonSeeMore;

    const data = [
        {
            id: 1,
            name: 'Exo Store',
            color: { backgroundColor: '#0000001A' },
            url: '/exo-store',
            urlEcommerce: '/ecommerce/exo-store',
            images: [magento2storeOnline, logoExo, webExo]
        },
        {
            id: 2,
            name: 'Felfort',
            color: { backgroundColor: ' #F0F1FA' },
            url: '/felfort',
            urlEcommerce: '/ecommerce/felfort',
            images: [pwa, logoFelfort, webFelfort]
        },
        {
            id: 3,
            name: 'gpsFarma',
            color: { backgroundColor: '#FAF4F0' },
            url: '/gps-farma',
            urlEcommerce: '/ecommerce/gps-farma',
            images: [magento2storeOnline, logoGpsFarma, webGpsFarma]
        },
        {
            id: 4,
            name: 'Prisma',
            color: { backgroundColor: '#E8EAF2' },
            url: '/prisma',
            urlEcommerce: '/ecommerce/prisma',
            images: [paymenthsMethods, logoPrisma, webPrisma]
        }
    ];

    const allData = [
        ...data,
        {
            id: 5,
            name: 'Vodafone',
            color: { backgroundColor: '#F0F1FA' },
            url: '/vodafone',
            urlEcommerce: '/ecommerce/vodafone',
            images: [wordpress, logoVodafone, webVodafone]
        },
        {
            id: 6,
            name: 'Store Newsan',
            color: { backgroundColor: '#FAF4F0' },
            url: '/newsan-store',
            urlEcommerce: '/ecommerce/newsan-store',
            images: [pwa, logoStoreNewsan, webStoreNewsan]
        },
        {
            id: 7,
            name: 'Newsan',
            color: { backgroundColor: '#F2F1F4' },
            url: '/newsan-magento',
            urlEcommerce: '/ecommerce/newsan-magento',
            images: [magento2storeOnline, logoNewsan, webNewsan]
        }
    ];

    const titleDesktop = store
        ? 'A few of our projects'
        : 'Algunos de nuestros Proyectos';

    const dataDesktopOne = [
        {
            id: 1,
            img: exoDesktop,
            imgHover: exoHover,
            englishText: (
                <p>
                    <strong>Exo</strong> is one of the first major Customers.
                    They accompanied us in our growth as an Agency and we
                    accompanied them in their growth in{' '}
                    <strong>#e-commerce</strong>. We launched your new store in
                    the productive <strong>Adobe Commerce Cloud</strong> and we
                    are proud to have helped you get here.
                </p>
            ),
            spanishText: (
                <p>
                    <strong>Exo </strong>es uno de los primeros Clientes
                    importantes. Ellos nos acompañaron en nuestro crecimiento
                    como Agencia y nosotros a ellos en su crecimiento en el{' '}
                    <strong>#e-commerce.</strong> Lanzamos su nueva tienda en{' '}
                    <strong>Adobe Commerce Cloud</strong> productiva y es un
                    orgullo poder haberlos ayudado a llegar hasta acá.
                </p>
            ),
            url: '/exo-store',
            urlEcommerce: '/ecommerce/exo-store'
        },
        {
            id: 2,
            img: felfortDesktop,
            imgHover: felfortHover,
            englishText: (
                <p>
                    In 2022 we went into production with the{' '}
                    <strong>#PWAStudio</strong> of{' '}
                    <strong>Felfort - La Delicia Felipe Fort S.A.</strong> The
                    store is built on Adobe Commerce Cloud and the entire
                    frontend in <strong>#PWA.</strong>
                </p>
            ),
            spanishText: (
                <p>
                    En 2022 salimos a producción con la{' '}
                    <strong>#PWAStudio</strong> de{' '}
                    <strong>Felfort – La Delicia Felipe Fort S.A.</strong> La
                    tienda se encuentra armada sobre Adobe Commerce Cloud y todo
                    el frontend en <strong>#PWA.</strong>
                </p>
            ),
            url: '/felfort',
            urlEcommerce: '/ecommerce/felfort'
        },
        {
            id: 3,
            img: gpsFarmaDesktop,
            imgHover: gpsHover,
            englishText: (
                <p>
                    We work with the brand on the{' '}
                    <strong>platform redesign project.</strong> Today it is in a
                    beginning stage and from IURCO we are collaborating with
                    suggestions from the UX-UI side to improve the User's
                    shopping experience. On the other hand, we are doing a{' '}
                    <strong>staff augmentation</strong> with an eCommerce
                    Manager who is in charge of helping the company to implement
                    the best options to increase sales.
                </p>
            ),
            spanishText: (
                <p>
                    Trabajamos con la marca en el{' '}
                    <strong>proyecto de rediseño de la plataforma.</strong> Hoy
                    la misma esta en una etapa de inicio y desde IURCO estamos
                    colaborando con sugerencias desde el lado de UX-UI para
                    mejorar la experiencia de compra del Usuario. Por otra
                    parte, estamos haciendo un{' '}
                    <strong>staff augmentation</strong> con un eCommerce Manager
                    que se encarga de ayudar a la empresa en implementar las
                    mejores opciones para aumentar las ventas.
                </p>
            ),
            url: '/gps-farma',
            urlEcommerce: '/ecommerce/gps-farma'
        },
        {
            id: 4,
            img: prismaDesktop,
            imgHover: prismaHover,
            englishText: (
                <p>
                    At the beginning of 2021, Prisma entrusted us with the{' '}
                    <strong>
                        development and integration of the “Payment Methods“
                    </strong>{' '}
                    Module for 3 e-commerce platforms: PrestShop, Woocommerce
                    and Magento 2. IURCO will be in charge of Support and
                    Delivery for the integration into their platforms.
                </p>
            ),
            spanishText: (
                <p>
                    A inicios del 2021 Prisma nos encomendó el{' '}
                    <strong>
                        desarrollo e integración del Modulo “Métodos de Pago”
                    </strong>{' '}
                    para 3 plataformas de e-commerce: PrestShop, Woocommerce y
                    Magento 2. IURCO sera el encargado del Support y Delivery de
                    la integración en sus plataformas.
                </p>
            ),
            url: '/prisma',
            urlEcommerce: '/ecommerce/prisma'
        }
    ];

    const dataDesktopTwo = [
        {
            id: 5,
            img: vodafoneDesktop,
            imgHover: vodafoneHover,
            englishText: (
                <p>
                    At the beginning of the year 2020 we were summoned by{' '}
                    <strong>Vodafone Uplift</strong> for the development and
                    layout of its{' '}
                    <strong>Technology Website: #wordpress.</strong>
                </p>
            ),
            spanishText: (
                <p>
                    A comienzo del año 2020 fuimos convocados por{' '}
                    <strong>Vodafone Upflit</strong> para el desarrollo y
                    maquetado de su Sitio Web{' '}
                    <strong>Tecnologia: #wordpress.</strong>
                </p>
            ),
            url: '/vodafone',
            urlEcommerce: '/ecommerce/vodafone'
        },
        {
            id: 6,
            img: storeNewsanDesktop,
            imgHover: storeNewsanHover,
            englishText: (
                <p>
                    In the last five months we have been working closely with
                    the <strong>Newsan</strong> team to launch its new
                    multi-brand online store. With{' '}
                    <strong>Adobe Commerce</strong> On Premise as technology and
                    also using <strong>#PWA Studio</strong> for the frontend, we
                    managed to deliver a store that will surely be the talk of
                    eCommerce for years to come.
                </p>
            ),
            spanishText: (
                <p>
                    En los últimos cinco meses estuvimos trabajando codo a codo
                    con el equipo de <strong>Newsan</strong> para poder lanzar
                    su nueva tienda online multimarca. Con{' '}
                    <strong>Adobe Commerce</strong> On Premise como tecnología y
                    usando también <strong>#PWA Studio</strong> para el frontend
                    logramos entregar una tienda que seguramente de que hablar
                    en el eCommece durante los próximos años.
                </p>
            ),
            url: '/newsan-store',
            urlEcommerce: '/ecommerce/newsan-store'
        },
        {
            id: 7,
            img: newsanDesktop,
            imgHover: newsanHover,
            englishText: (
                <p>
                    Design, development and migration of all its online stores{' '}
                    <strong>(Philco, Atma, Noblex, Siam)</strong> to Magento2.
                    We provide the same Delivery and Support service for your
                    B2B store. In addition, we manage all your production,
                    staging and development environments (-50 servers).
                </p>
            ),
            spanishText: (
                <p>
                    Diseño, desarrollo y migración de todas sus tiendas online{' '}
                    <strong>(Philco, Atma, Noblex, Siam)</strong> a Magento2.
                    Brindamos el mismo servicio de Delivery y Support para su
                    tienda B2B. Ademas, manejamos todos sus entornos
                    productivos, staging y desarrollo (-50 servidores).
                </p>
            ),
            url: '/newsan-magento',
            urlEcommerce: '/ecommerce/newsan-magento'
        },
        {
            id: 1,
            img: exoDesktop,
            imgHover: exoHover,
            englishText: (
                <p>
                    <strong>Exo</strong> is one of the first major Customers.
                    They accompanied us in our growth as an Agency and we
                    accompanied them in their growth in{' '}
                    <strong>#e-commerce</strong>. We launched your new store in
                    the productive <strong>Adobe Commerce Cloud</strong> and we
                    are proud to have helped you get here.
                </p>
            ),
            spanishText: (
                <p>
                    <strong>Exo </strong>es uno de los primeros Clientes
                    importantes. Ellos nos acompañaron en nuestro crecimiento
                    como Agencia y nosotros a ellos en su crecimiento en el{' '}
                    <strong>#e-commerce.</strong> Lanzamos su nueva tienda en{' '}
                    <strong>Adobe Commerce Cloud</strong> productiva y es un
                    orgullo poder haberlos ayudado a llegar hasta acá.
                </p>
            ),
            url: '/exo-store',
            urlEcommerce: '/ecommerce/exo-store'
        }
    ];

    return {
        title,
        textButton,
        textButtonSeeMoreProjects,
        textButtonSeeLessProjects,
        data,
        iconButton,
        iconButtonSeeMoreProjects,
        allData,
        titleDesktop,
        dataDesktopOne,
        dataDesktopTwo,
        imgDesktop
    };
};
