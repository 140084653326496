const browserLanguage = navigator.language || navigator.userLanguage; 

const defaultLenguage = 
    browserLanguage.includes("en") ? true 
    : JSON.parse(localStorage.getItem('lenguage'));

const types = {
    english: 'english',
    spanish: 'spanish'
};

const StoreReducer = (state, action)=> {
    if(action.type === types.english) {
        localStorage.setItem("lenguage", JSON.stringify(true));       
        return true
    } else if (action.type === types.spanish) {
        localStorage.setItem("lenguage", JSON.stringify(false));
    }
    
    return false
};

export { defaultLenguage, types }
export default StoreReducer