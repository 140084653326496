import consultancyImg from '../../assets/ship.gif';
import designImg from '../../assets/diamond.gif';
import webProgrammingImg from '../../assets/code.gif';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);

    const items = [
        {
            title: store ? "Consultancy" : "Consultoría",
            description_list: 
                [
                    "Benchmark",
                    store ? "Strategy" : "Estrategia",
                    store ? "Logistics and Design of operation processes" :  "Logística y diseño de procesos de operación",
                    store ? "ROI Analysis" : "Análisis de ROI"
                ],
            img: consultancyImg
        },
        {
            title: store ? "UI/UX Design" : "Diseño ui/ux",
            description_list: 
                [
                    store ? "Creativity and Development" : "Creatividad y desarrollo",
                    store ? "User Experience" : "Experiencia de usuario",
                    store ? "Interface Design" : "Diseño de interfaces",
                    store ? "Information Architecture" :  "Arquitectura de la información",
                    store ? "Mobile-first Design" : "Diseño mobile first"
                ],
            img: designImg
        },
        {
            title: store ? "Development" : "Desarrollo",
            description_list: 
                [
                    store ? "Magento Implementations" : "Implementaciones Magento",
                    store ? "Node JS Development" : "Desarrollo en Node JS",
                    store ? "System Integrations" : "Integración de Sistemas externos",
                    store ? "PWA App Developments" :  "Desarrollos de apps PWA"
                ],
            img: webProgrammingImg
        }
    ]
    
    const textButton = store ? "More info" : "Más info";
    
    return {
       items,
       textButton
    };
};
