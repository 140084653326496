import React from 'react';
import Landing from '../landing';
import Ubication from '../../Ubication/ubication';
import WorkTogether from '../../WorkTogether/workTogether';
import OurProjects from '../../OurProjects/ourProjects';
import styles from './prismaPage.module.css';
import { useSampleData } from './sampleData';
import ContactForm from '../../ContactForm/contactForm';
import Footer from '../../Footer/footer';

const PrismaPage = () => {
    const { 
        technology,
        storePrisma,
        textMethodology,
        methodologyPrisma,
        prisma
     } = useSampleData();

    return (
        <div>
            <Landing
                item={technology}
                store={storePrisma}
                textMethodology={textMethodology}
                methodology={methodologyPrisma}
                prisma={prisma}
            />
            <div className={styles.root}>
                <OurProjects landing={true}/>
                <WorkTogether/>
                <div className={styles.contactFormAndUbication}>
                    <div className={styles.contactFormRoot}>
                        <ContactForm/>
                    </div>
                    <Ubication/>
                </div>
                <Footer/>
            </div>
        </div> 
    )
}

export default PrismaPage