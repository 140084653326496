import React, {Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logoHeader.svg';
import menu from '../../assets/menu.svg';
import styles from './header.module.css';
import LenguageButton from './LenguageButton/lenguageButton';
import DropdownMenu from './DropdownMenu/dropdownMenu';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

const Header = (props)=> {
    const [showMenu, setShowMenu] = useState(false);

    const {
        methodology,
        weAre,
        weMake,
        contact,
        theSayAboutUs,
        projects,
        customer,
        isEcommerce = false,
        isEcommercePage
    } = props;

    const [ store ] = useContext(StoreContext);

    const handleClick = () => {
        setShowMenu(!showMenu);
    } 

    const itemsEcommerceDesktop = [
        {title:  store ? "tecnologies" : "tecnologías", top: 800},
        {title:  store ? "Projects" : "proyectos", top: 1100},
        {title:  store ? "ask for budget" : "Pedir presupuesto", top: 10000},
    ];

    const handleLinkClick = (top) => {
        window.scrollTo({
            top: top,
            behavior: 'smooth',
        })
    };    

    return (
        <Fragment>
            {
                showMenu && 
                <DropdownMenu 
                    showMenu={showMenu} 
                    setShowMenu={setShowMenu}
                    methodology={methodology} 
                    weAre={weAre}
                    weMake={weMake}
                    contact={contact}
                    theSayAboutUs={theSayAboutUs}
                    projects={projects}
                    customer={customer}
                    isEcommerce={isEcommerce}
                    handleLinkClick={handleLinkClick}
                />
            }
            <div className={styles.root}> 
                <div className={styles.menuLogo}> 
                    {
                        isEcommerce ? 
                        <Link onClick={()=> handleLinkClick(0)}>
                            <img src={logo} className={styles.logo} alt="IURCO"/>
                        </Link> : 
                        <Link to={isEcommercePage ? "/ecommerce" : "/"} onClick={()=> handleLinkClick(0)}>
                            <img src={logo} className={styles.logo} alt="IURCO"/>
                        </Link>
                    }
                    <img onClick={handleClick} src={menu} className={isEcommerce ? styles.menuEcommerce : styles.menu} alt="Menu"/>
                </div>
                <div className={isEcommerce ? styles.itemsDesktopRoot : styles.itemsDesktopRootHidden}>
                    { isEcommerce &&
                    <div className={styles.itemsDesktop}>
                        {   itemsEcommerceDesktop.map((item, index) => {
                                return (
                                    <div key={index} className={styles.itemRoot}>
                                        <Link  
                                            onClick={()=> handleLinkClick(item.top)}
                                            className={styles.link}
                                        >
                                            <p className={(item.title === 'Pedir presupuesto' || item.title === 'ask for budget') ? styles.itemAskForBudget : styles.item}>
                                                {item.title}
                                            </p>
                                        </Link>
                                        {
                                        (item.title === "tecnologías" || item.title === "tecnologies") && 
                                        <span>|</span>
                                        }
                                    </div>
                                )
                        })
                    }
                    </div>
                    }
                    <div className={styles.languageContainer}>
                        <LenguageButton/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default Header