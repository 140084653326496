import React from 'react';
import { items } from '../sampleData';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './sliderTechnologies.module.css';

const SliderTechnologies = () => {
    const settings = {
        dots:false,
        slidesToShow: 3,
        slidesToScroll: 3,   
    };

    return (
        <div className={styles.root}>
            <Slider {...settings}>
                {items.map((item, index) => {
                    return(
                        <div className={styles.container} key={index}>
                            <img className={styles.img}
                                src={item.img} 
                                alt="logo" 
                                key={index}
                            />
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default SliderTechnologies

