import mailIcon from '../../assets/mailIcon.svg';
import localIcon from '../../assets/localIcon.svg';
import sendIcon from '../../assets/sendIcon.svg';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = () => {
    const [ store ] = useContext(StoreContext);

    const emailText = store ? "Thank you for your message!" : "¡Gracias por tu mensaje!";

    const genericErrorText = store ? "Please complete all fields and complete the reCAPTCHA." : "Por favor, complete todos los campos y el reCAPTCHA."

    const title = store ? 'Contact us' : 'Contactanos'; 

    const iconButton = sendIcon;

    const items = [
        {text: 'info@iurco.com', img: mailIcon, id: 1 },
        
        {text: <p>Belgrano 518, Cañuelas,<br/> Buenos Aires - Argentina.</p>, img: localIcon, id: 2 }
    ];

    const textButton = store ? 'Send message' : 'enviar mensaje';

    const textButtonLoading = store ? 'Sending...' : 'enviando...';

    return {
       title,
       iconButton,
       items,
       textButton,
       emailText,
       genericErrorText,
       textButtonLoading
    };
};

