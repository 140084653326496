import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Landing from '../../landing';
import Ubication from '../../../../Ubication/ubication';
import WorkTogether from '../../../../WorkTogether/workTogether';
import OurProjects from '../../../../OurProjects/ourProjects';
import styles from './newsanMagento.module.css';
import { useSampleData } from './sampleData';
import ContactForm from '../../../../ContactForm/contactForm';
import Footer from '../../../../Footer/footer';

const NewsanMagentoPageEcommerce = () => {
    const { 
        buttons,
        storeNewsan,
        methodology,
        technology,
        textMethodology,
        otherProjects, 
        otherProjectsText,
        b2bText,
        b2bTitle,
        newsanMagento 
    } = useSampleData();

    return (
        <div>
            <Landing 
                item={technology}
                buttons={buttons}
                store={storeNewsan}
                methodology={methodology}
                textMethodology={textMethodology}
                otherProjects={otherProjects}
                otherProjectsText={otherProjectsText}
                b2bText={b2bText}
                b2bTitle={b2bTitle}
                newsanMagento={newsanMagento}
            />
            {  
                 newsanMagento  &&
                    <div className={styles.b2b}>
                        <h3>{b2bTitle}</h3>
                        <p>{b2bText}</p>
                        <div>
                            <img
                                className={styles.imgNewsanB2b}
                                src={otherProjects.imgNewsanB2b}
                                alt='imgNewsan'
                            />
                            <img
                                className={styles.imgMagentoPhonos}
                                src={otherProjects.imgMagentoPhonos}
                                alt='imgMagento'
                            />
                        </div>
                    </div>
            }
            <OurProjects landing={true} isEcommercePage={true}/>
            <WorkTogether/>
                <div className={styles.contactFormAndUbication}>
                    <div className={styles.contactFormRoot}>
                        <ContactForm/>
                    </div>
                    <Ubication/>    
                </div>  
                <Footer/>
            </div>    
    )
}

export default NewsanMagentoPageEcommerce