import React from 'react';
import Slider from "react-slick";
import { useSampleData } from '../sampleData';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './sliderHubs.module.css';

const SliderHubs = ({itemsForSliderOne, itemsForSliderTwo}) => {
    const { title } = useSampleData();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className={styles.root}>
            <h3 className={styles.title}>{title}</h3>
            <Slider {...settings}>
                <div className={styles.slideItemOne}>
                    {
                        itemsForSliderOne.map((item ) => {
                            return (
                                <div className={styles.slideRoot} key={item.id}> 
                                    <img
                                        src={item.img}
                                        alt="icon"
                                        width={30}
                                        height={30}
                                    />
                                    <p className={styles.name}>{item.title}</p>
                                </div>
                                
                            )
                       })
                    }
                </div>
                <div className={styles.slideItemTwo}>
                    {
                        itemsForSliderTwo.map((item ) => {
                            return (
                                <div className={styles.slideRoot} key={item.id}>
                                    <img
                                        src={item.img}
                                        alt="icon"
                                        width={20}
                                        height={20}
                                    />
                                    <p className={styles.name}>{item.title}</p>
                                </div>
                            )
                       })
                    }
                </div>
            </Slider>
        </div>
    )
}

export default SliderHubs
