import styles from './packs.module.css';

const Item = ({index, item, iconButton, handleAccordion, openAccordion, isRow = false}) => {

    const classItem = openAccordion === index ? styles.itemOpen : styles.item;
    const classNumber = openAccordion === index ? styles.openNumber : styles.number;
    const classTitle = openAccordion === index ?  styles.itemTitleOpen : styles.itemTitle;
    const classText = openAccordion === index ? styles.text : styles.textClosed;
    const classImg = openAccordion === index ? styles.img : styles.imgOpen;

    return (
        <div  
            style={{width: isRow ? item.width : 'none'}}
            key={index}
            className={classItem}
            onClick={() => handleAccordion(index)}
        >
            <div className={styles.titleRoot}>
                <p className={classNumber}>{item.number}</p>
                <p className={classTitle}>{item.title}</p>
            </div>
            <p className={classText}>{item.text}</p>
            <img className={classImg} src={iconButton} alt='arrow icon'/>
        </div>
    );
};

export default Item;