import React from 'react';
import abaoutAsDots from '../../assets/aboutAsdots.svg';
import mouse from '../../assets/mouse.svg';
import vector from '../../assets/vector.svg';
import styles from './aboutAs.module.css';
import { useSampleData } from './sampleData';

const AboutAs = () => {
    const { text, title } = useSampleData();

    const onClick = ()=> {
        window.scroll({
            top: 820,
            behavior: 'smooth'
        });
    }

    return (
        <div>
            <div className={styles.mouseRoot}>
                <button onClick={onClick} className={styles.mouseButton}>
                    <img
                        className={styles.mouseImg}
                        src={mouse}
                        alt="icon title"
                        height={40}
                        width={30}
                    />
                    <img
                        className={styles.vectorImg}
                        src={vector}
                        alt="icon title"
                        height={30}
                        width={20}
                    />
                </button>
            </div>
            <div className={styles.root}>
                <div className={styles.titleRoot}>
                    <img
                        className={styles.img}
                        src={abaoutAsDots}
                        alt="icon title"
                        height={120}
                        width={110}
                    />
                    <h3 className={styles.title}>{title}</h3>
                </div>
                <p className={styles.text}>
                    {text}
                </p>
            </div>
        </div>
    )
}

export default AboutAs