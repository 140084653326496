import logoLinkedin from '../../assets/logoLinkedinFooterMobile.svg';
import logoIurco from '../../assets/logoIurcoFooterMobile.svg';
import logoFacebook from '../../assets/logoFacebookFooterMobile.svg';
import logoInstagram from '../../assets/LogoInstagramFooterMobile.svg';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);

    const {
        methodology,
        weAre,
        weMake,
        contact,
        theSayAboutUs,
        projects,
        customer
    } = props;
 
    const socialMedia = [
        {
            src: logoFacebook,
            name: "facebook",
            url: "https://www.facebook.com/helloiurco"
        },
        {
            src: logoLinkedin,
            name: "linkedin",
            url:"https://www.linkedin.com/company/iurco/"
        },
        {
            src: logoInstagram,
            name: "instagram",
            url:"https://www.instagram.com/helloiurco"
        }
    ];
    
    const links = [
        {
            name: store ? "we are" : "somos",
            path: weAre,
            top: 400
        },
        {
            name: store ? "we make" : "hacemos",
            path: weMake,
            top: 1900
        },
        {
            name: store ? "they say about us" : "dicen de nosotros",
            path: theSayAboutUs,
            top: 2400
        },
        {
            name: store ? "Projects" : "proyectos",
            path: projects,
            top: 2900
        },
        {
            name: store ? "methodology" : "metodología",
            path: methodology,
            top: 4700
        },
        {
            name: store ? "customers" : "clientes",
            path: customer,
            top: 4400
        },
        {
            name: store ? "contact" : "contacto",
            path: contact,
            top: 100000
        }
    ]

    const ecommerceLinks = [
        {
            name: store ? "technologies" : "tecnologías",
            top: 800
        },
        {
            name: store ? "Projects" : "proyectos",
            top: 1100
        },
        {
            name: store ? "contact" : "contacto",
            top: 3200
        }
    ]

    return {
        logoIurco, socialMedia, links, ecommerceLinks
    };
};
