import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "../Slick-carousel/slick-theme.css";
import styles from './sliderOpinions.module.css';
import { useSampleData } from './sampleData';

const SliderOpinions= () => {
    const {title, items, openIcon, closeIcon } = useSampleData();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    return (
        <div className={styles.root}>
            <h1 className={styles.title}>{title}</h1>
                <Slider {...settings}>
                    {items.map((item, index) => {
                        return (
                            <div className={styles.slide} key={index}>
                                <img className={styles.img}
                                    src={openIcon}
                                    alt="icon"                     
                                    height={70} 
                                    width={70}
                                />
                                <div className={styles.container} style={{backgroundImage: `url(${item.ImgBackground})`}}>
                                    {item.text}
                                    <div className={styles.owner}>
                                        <p>{item.owner}</p>
                                        <p className={styles.client}>{item.client}</p>
                                    </div>
                                    <div className={styles.imgClose}>
                                        <img   
                                            src={closeIcon}
                                            alt="icon" 
                                            height={25} 
                                            width={25} 
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
        </div>
    )
}

export default SliderOpinions