import ecommerce from '../../assets/ecommerceIcon.svg';
import hosting from '../../assets/hostingIcon.svg';
import uxUiDesign from '../../assets/uxUiDesignIcon.svg';
import mobileApps from '../../assets/mobileAppsIcon.svg';
import software from '../../assets/softwareIcon.svg';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';

export const useSampleData = props => {
    const [ store ] = useContext(StoreContext);

    const title = store ? "Our hubs" : 'Nuestros hubs';

    const items = [
        {title: 'ecommerce', img: ecommerce, id: 1 },
        {title: 'mobile apps', img: mobileApps, id: 2 },
        {title: 'software', img: software, id: 3 },
        {title: 'hosting', img: hosting, id: 4 },
        {title: 'ux ui design', img: uxUiDesign, id: 5 }
    ];

    return {
        title,
        items
    };
};
