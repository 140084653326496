import React from 'react';
import styles from './projectsProcess.module.css';
import { useSampleData } from './sampleData';

const ProjectProcess = () => {
    const { title, iconMobile, items } = useSampleData();

    return ( 
        <div className={styles.root}>
            <p className={styles.title}>{title}</p>
            <div className={styles.itemsContainer}>
                {
                    items.map((item,index) => {
                        return (
                            <div key={index} className={styles.item}>
                                <h3 className={styles.itemTitle}>{item.title}</h3>
                                <p className={(item.title === "Get ready" || item.title === "Development") ? styles.subtitleSmall : styles.subtitle}>{item.subtitle}</p>
                                <p className={styles.text}>{item.text}</p>
                                {item.title !== "Drive" && <img className={styles.img} src={iconMobile} alt='arrow icon'/>}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ProjectProcess
