import React from 'react';
import styles from './infoImages.module.css';
import { useSampleData } from '../sampleData';

const InfoImages = ({ isMobile }) => {
    const { images } = useSampleData();

    return (
        <div className={styles.root}>
            {images.map((img, index) => {
                return (
                    <div key={index} className={styles.imgRoot}>
                        <img 
                            className={styles.img}
                            src={isMobile ? img.mobile : img.desktop}
                            alt="our team icon"
                        />
                        <div className={styles.numberAndText}>
                            <p className={styles.number}>{img.number}</p>
                            <p className={styles.text}>{img.text}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default InfoImages
