import React from 'react';
import styles from './methodology.module.css';
import { useSampleData } from './sampleData';
import methodologyIcon from '../../assets/methodology.svg';

export const Methodology = () => {
    const { text, title, img, items, titleMethodology} = useSampleData();

    return (
        <div className={styles.root}>
            <div className={styles.containerRoot}>
                <p className={styles.title}>{title}</p>
                <p className={styles.titleMethodology}>{titleMethodology}</p>
                <div className={styles.text}>
                    {text}
                </div>
                <img 
                    className={styles.img}
                    src={img}
                    alt="logo"
                />
                 <img 
                    className={styles.imgDesktop}
                    src={methodologyIcon}
                    alt="logo"
                />
            </div>
            <div className={styles.itemContainer}>
                {items.map((item,index) => {
                    return (
                        <div className={styles.item} key={index}>
                            <img className={index !== 0 ? styles.imgItem : styles.imgSpecial}
                                src={item.img}
                                alt="logo"
                            />
                            <p className={index !== 0 ? styles.description : styles.descriptionSpecial}>
                                {item.description}
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Methodology