import iconLogo from '../../../assets/logoFelfortLanding.svg';
import imgFelfort from '../../../assets/imgMobileFelfortLanding.png';
import imgFelfortDesktop from '../../../assets/imgFelfortLandingDesktop.png';
import imgFelfortPhones from '../../../assets/imgFelfortLandingPhones.png';
import imgOnlyMobile from '../../../assets/imgOnlyMobileFelfort.png';
import imgMobileAndDesktop from '../../../assets/imgDesktopRightFelfort.png';
import imgDesktopRight from '../../../assets/imgOnlyMobileFelfort.png';
import iconTeam from '../../../assets/equipo.gif';
import iconSprintDuration from '../../../assets/duracion.gif';
import iconProcess from '../../../assets/proceso.gif';
import iconTechnology from '../../../assets/pwa_magenta.gif';
import { useContext } from 'react';
import { StoreContext } from '../../Store/storeProvider';

export const useSampleData = (props) => {
    const [store] = useContext(StoreContext);

    const technology = [
        {
            img: iconLogo,
            title: 'E-COMMERCE PWA',
            subtitle: store ? (
                <>
                    <h4>FELFORT STORE |</h4>
                    <p>Online store</p>
                </>
            ) : (
                <>
                    <h4>TIENDA FELFORT |</h4>
                    <p>Tienda Online</p>
                </>
            ),
            text: store
                ? 'When Felfort contacted us, the need for change was very apparent. Users sent inquiries asking if they could buy the products and how they should do it. Basically, it was not clear that the site was an e-commerce and the usability was not adequate.'
                : 'Cuando Felfort nos contactó, la necesidad de un cambio era muy evidente. Los usuarios enviaban consultas preguntando si podían comprar los productos y cómo debían hacerlo. Basicamente, no estaba claro que el sitio era un e-commerce y la usabilidad no era la adecuada.'
        }
    ];

    const buttons = [
        {
            buttonText: store ? 'WEB SITE' : 'SITIO',
            url: 'https://www.felfort.com.ar'
        }
    ];

    const storeFelfort = [
        {
            title: store ? 'FELFORT STORE' : 'TIENDA FELFORT',
            text: store
                ? 'In 2022 we went into production with the #PWAStudio of Felfort - La Delicia Felipe Fort S.A. The store is built on Adobe Commerce Cloud and the entire frontend in #PWA.'
                : 'En 2022 salimos a producción con la #PWAStudio de Felfort - La Delicia Felipe Fort S.A. La tienda se encuentra armada sobre Adobe Commerce Cloud y todo el frontend en #PWA.',
            img: imgFelfort,
            imgDesktop: imgFelfortDesktop,
            imgPhones: imgFelfortPhones
        }
    ];

    const textMethodology = store
        ? 'AS WE DO FOR EACH PROJECT, WE WORK UNDER THE SCRUM METHODOLOGY, COLLABORATIVELY AND AS A TEAM TO OBTAIN THE BEST POSSIBLE RESULT OF THE PROJECT'
        : 'COMO LO HACEMOS PARA CADA PROYECTO TRABAJAMOS BAJO LA METODOLOGÍA SCRUM, COLABORATIVAMENTE Y EN EQUIPO PARA OBTENER EL MEJOR RESULTADO POSIBLE DEL PROYECTO';

    const otherProjectsText = store
        ? 'LEARN MORE ABOUT THIS PROJECT'
        : 'CONOCÉ MÁS ACERCA DE ESTE PROYECTO';

    const otherProjects = {
        imgOnlyMobile,
        imgMobileAndDesktop,
        imgDesktopRight,
        altImgMobileAndDesktop: 'imgFelfort',
        altImgDesktopRight: 'ImgFelfor'
    };

    const methodologyFelfort = [
        {
            img: iconTeam,
            title: store ? 'TEAM' : 'EQUIPO',
            description: store
                ? ['1 designer UX / UI', '3 developers', '1 p.m ', '1 QA ']
                : [
                      '1 diseñadora UX / UI',
                      '3 desarrolladores',
                      '1 p.m ',
                      '1 control de calidad '
                  ]
        },
        {
            img: iconSprintDuration,
            title: store ? 'SPRINT DURATION' : 'DURACIÓN DEL SPRINT',
            description: store
                ? [
                      '6 sprints from inception to product delivery fully response'
                  ]
                : [
                      '6 sprints desde el inicio hasta la entrega del producto totalmente response'
                  ]
        },
        {
            img: iconProcess,
            title: store ? 'PROCESS' : 'PROCESO',
            description: store
                ? [
                      'Evaluation of the product, its user and its competition, creation of the information architecture and selection of the type of technology to use'
                  ]
                : [
                      'Evaluación del producto, su usuario y su competencia, creación de la arquitectura de la información y selección del tipo de tecnología a usar'
                  ]
        },
        {
            img: iconTechnology,
            title: store ? 'TECHNOLOGY' : 'TECNOLOGÍA',
            description: store
                ? [
                      'PWA, a stack of technologies that allows the use of software on any platform compatible with standard browsers.'
                  ]
                : [
                      'PWA, un stack de tecnologías q permite el uso de sofware sobre cualquier plataforma compatible con navegadores standar.'
                  ]
        }
    ];

    const felfort = true;

    return {
        technology,
        buttons,
        storeFelfort,
        textMethodology,
        otherProjectsText,
        otherProjects,
        felfort,
        methodologyFelfort
    };
};
