import React from 'react'
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';
import styles from "./weMakeEcommerce.module.css"
import ocaImg from "../../assets/oca-logo.png"
import cruzAzulImg from "../../assets/cruz-del-sur-logo.png"
import adreaniImg from "../../assets/andreani-logo.png"
import mercadoPagoiImg from "../../assets/mercado-pago-logo.png"
import spsImg from "../../assets/SPS-logo.png"
import todoPagoImg from "../../assets/todo-pago-logo.png"
import mercadoLibreImg from "../../assets/mercado-libre-logo.png"
import iconCheck from "../../assets/icon_check.svg"
import pointImg from "../../assets/points.svg"

const WeMakeEcommerce = () => {
    const [ store ] = useContext(StoreContext);

    const title = store ? "We make modules tailored to your business and integrate with your ERP or billing system and more:"  : "Hacemos módulos a la medida de tu negocio e integramos con tu ERP o sistema de facturación y más:"

    const arrayOfElements = [
        { 
            title: store ? 'LOGISTICS' : 'LOGÍSTICA',
            images: [
                {img: ocaImg},
                {img: cruzAzulImg},
                {img: adreaniImg}
            ]
        },
        {
            title: store ? 'PAYMENT METHODS' : 'MÉTODOS DE PAGO',
            images: [
                {img: mercadoPagoiImg},
                {
                    name: "spsImg",
                    img: spsImg
                },
                {img: todoPagoImg}
            ] 
        },
        {
            title: 'MARKETPLACE',
            images: [
                {
                    name: "mercadoLibre",
                    img: mercadoLibreImg
                },
            ]
        }   
    ]

    return (
        <div className={styles.root}>
            <p className={styles.title}>{title}</p>
            <div className={styles.itemRoot}>
                {arrayOfElements.map((item, index) => {
                    return (
                        <div key={index} className={styles.containerTitleAndImg}>
                            <div className={styles.containerTitle}>
                                <img className={styles.iconCheck} alt='icon' src={iconCheck}/>
                                <p className={styles.titleImg}>{item.title}</p>
                            </div>
                            <div className={styles.imagesRoot}>
                            {
                                item.images.map((image) => {
                                    return (
                                        <img className={(image.name === "spsImg" ) ? styles.spsImg : image.name === "mercadoLibre" ? styles.mercadoLibreImg : styles.imgIcon} alt='icon' src={image.img}/> 
                                    )
                                })
                            }
                            </div>
                        </div>
                    )}
                )}
            </div>
            <img className={styles.points} alt='points icon' src={pointImg}/>
        </div>  
    )
}

export default WeMakeEcommerce