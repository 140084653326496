import React from 'react';
import useViewportWidth from '../../talons/ViewportWidth/useViewportWidth';
import { useSampleData } from './sampleData';
import SliderHubs from './SliderHubs/sliderHubs';
import styles from './hubs.module.css';

const Hubs = () => {
    const { items, title } = useSampleData();

    const width = useViewportWidth();

    const itemsForSliderOne = items.filter((item) => {
        if(item.title === 'ecommerce' || item.title === 'mobile apps' || item.title === 'software') {
            return true
        }
        return null
    });

    const itemsForSliderTwo = items.filter((item) => {
        if(item.title === 'hosting' || item.title === 'ux ui design') {
            return true
        }
        return null
    });

    if(width < 800) {
        return <SliderHubs itemsForSliderOne={itemsForSliderOne} itemsForSliderTwo={itemsForSliderTwo}/>
    }

    return (
        <div className={styles.root}>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.itemsRoot}>
            {
                items.map((item) => {
                    return (
                        <div className={styles.imgItem} key={item.id}> 
                            <img
                                src={item.img}
                                alt="icon"
                                className={styles.img}
                            />
                            <p className={styles.name}>{item.title}</p>
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}

export default Hubs
