import iconLogo from '../../../../assets/iconLogoVodafoneLanding.png';
import imgVodafone from '../../../../assets/imgVodafoneLanding.png';
import imgVodafoneDesktop from '../../../../assets/imgVodafoneDesktopLanding.png';
import imgVodafonePhones from '../../../../assets/imgVodafonePhonesLanding.png';
import iconTeam from '../../../../assets/equipo.gif';
import iconSprintDuration from '../../../../assets/duracion.gif';
import iconProcess from '../../../../assets/proceso.gif';
import iconTechnology from '../../../../assets/wordpress.gif';
import { useContext } from 'react'; 
import { StoreContext } from '../../../Store/storeProvider';

export const useSampleData = () => {
    const [ store ] = useContext(StoreContext);
 
    const technology = [
        {
            img: iconLogo,
            title: 'WORDPRESS',
            subtitle: store ? <><h4>VODAFONE UPLIFT |</h4><p>Website</p></> : <><h4>VODAFONE UPLIFT |</h4><p>Sitio Web</p></>,
            text: store ?  "At the beginning of the year 2020 we were summoned by Vodafone Uplift for the development and layout of its Website. Technology: #wordpress." :
                'A comienzo del año 2020 fuimos convocados por Vodafone Uplift para el desarrollo y maquetado de su Sitio Web. Tecnologia: #wordpress.',
        }
    ];
    
    const buttons = [{buttonText: store ? "WEB SITE" : 'SITIO', url: 'https://www.vodafone.de/unternehmen/uplift.html'}];
    
    const storeVodafone = [
        { 
            img: imgVodafone,
            imgDesktop: imgVodafoneDesktop,
            imgPhones: imgVodafonePhones
        }
    ];
    
    const textMethodology = store ? "AS WE DO FOR EACH PROJECT, WE WORK UNDER THE SCRUM METHODOLOGY, COLLABORATIVELY AND AS A TEAM TO OBTAIN THE BEST POSSIBLE RESULT" : 
        'COMO LO HACEMOS PARA CADA PROYECTO TRABAJAMOS BAJO LA METODOLOGÍA SCRUM, COLABORATIVAMENTE Y EN EQUIPO PARA OBTENER EL MEJOR RESULTADO POSIBLE';
    
    const otherProjectsText = store ? "LEARN MORE ABOUT THIS PROJECT" : 'CONOCÉ MÁS ACERCA DE ESTE PROYECTO'
    
    const methodologyVodafone = [
        {   img: iconTeam,
            title: store ? "TEAM" : 'EQUIPO',
            description: store ? 
                [   
                    '1 designer UX / UI',
                    '3 developers',
                    '1 p.m ',
                    '1 QA '
                ] : 
                [   
                    '1 diseñadora UX / UI',
                    '3 desarrolladores',
                    '1 p.m ',
                    '1 control de calidad '
                ]
        },
        {
            img: iconSprintDuration,
            title: store ? "SPRINT DURATION" : 'DURACIÓN DEL SPRINT',
            description: store ? ["6 sprints from inception to fully response product delivery"] : 
                ["6 sprints desde el inicio hasta la entrega del producto totalmente response"]
        },
        {
            img: iconProcess,
            title: store ? "PROCESS" : 'PROCESO',
            description: store ? ["Product evaluation, pain points and usability improvement possibilities."] :
                ['Evaluación del producto, los puntos de dolor y las posibilidades de mejora de la usabilidad.']
        },
        {
            img: iconTechnology,
            title: store ? "TECHNOLOGIES" : 'TECNOLOGÍA',
            description: store ? ["WordPress is a web content management system (CMS). It has an open source model, so it allows you to use plugins to create and modify any aspect of the website."] : 
                ['WordPress es un sistema de gestión de contenidos web (CMS). Tiene un modelo de código abierto, por lo que permite usar complementos para crear y modificar cualquier aspecto del sitio web.']
        }
    ]
    
    const vodafone = true;

    return {
        technology,
        buttons,
        storeVodafone,
        textMethodology,
        otherProjectsText,
        methodologyVodafone,
        vodafone
    };
};