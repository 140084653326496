import React from 'react';
import styles from './landing.module.css';
import iconButton from '../../../assets/iconButtonLanding.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useViewportWidth from '../../../talons/ViewportWidth/useViewportWidth';
import Header from '../../Header/header';

const Landing = (props) => {
    const {
        item,
        buttons,
        store,
        methodology,
        textMethodology,
        otherProjects,
        otherProjectsText,
        newsanMagento,
        imgBannerDesktop,
        storeNewsan,
        gpsFarma,
        exo,
        felfort,
        prisma,
        vodafone
    } = props;

    const width = useViewportWidth();

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: prisma ? 1 : 2,
        slidesToScroll: prisma ? 1 : 2
    };

    const textButton = newsanMagento ? 'VISITAR ' : 'VISITAR SITIO';

    return (
        <div className={styles.root}>
            <Header isEcommercePage={true}/>
            {item.map((obj, index) => {
                return (
                    <div className={styles.technology} key={index}>
                        <img
                            className={styles.imgTechnology}
                            src={obj.img}
                            alt="iconMagento2"
                        />
                        <h3>{obj.title}</h3>
                        <div
                            className={
                                storeNewsan
                                    ? styles.technology_subtitleStoreNewsan
                                    : prisma
                                    ? styles.technology_subtitlePrisma
                                    : styles.technology_subtitle
                            }
                        >
                            {obj.subtitle}
                        </div>
                        <div
                            className={
                                storeNewsan
                                    ? styles.technology_textStoreNewsan
                                    : styles.technology_text
                            }
                        >
                            {obj.text}
                        </div>
                    </div>
                );
            })}
            {!prisma && (
                <div
                    className={
                        newsanMagento
                            ? styles.buttons
                            : (gpsFarma || exo || vodafone) && width > 800
                            ? styles.buttonsGpsFarma
                            : styles.generalButtons
                    }
                >
                    {buttons.map((item, index) => {
                        return (
                            <div
                                className={
                                    newsanMagento
                                        ? styles.buttonRootNewsanMagento
                                        : styles.buttonRoot
                                }
                                key={index}
                            >
                                <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    key={index}
                                >
                                    <button
                                        className={
                                            newsanMagento && width < 800
                                                ? styles.buttonNewsanMagento
                                                : styles.button
                                        }
                                    >
                                        <div
                                            className={
                                                newsanMagento
                                                    ? styles.addedTextNewsanMagento
                                                    : styles.addedText
                                            }
                                        >
                                            {newsanMagento && width < 800
                                                ? ''
                                                : textButton}
                                            {newsanMagento
                                                ? item.buttonText
                                                : ''}
                                        </div>
                                        <img
                                            className={styles.imgButton}
                                            src={iconButton}
                                            alt="iconButton"
                                        />
                                    </button>
                                </a>
                            </div>
                        );
                    })}
                    {(storeNewsan || felfort) && (
                        <div className={styles.containerImgBannerDesktop}>
                            <img
                                src={imgBannerDesktop}
                                alt="imgBannerStoreNewsan"
                            />
                        </div>
                    )}
                </div>
            )}
            {store.map((item, index) => {
                return (
                    <div key={index}>
                        <div
                            className={
                                vodafone && width < 800
                                    ? styles.storeVodafone
                                    : styles.store
                            }
                            key={index}
                        >
                            <img
                                className={styles.imgStore}
                                src={item.img}
                                alt="imageStore"
                            />
                            <h3>{item.title}</h3>
                            <p>{item.text}</p>
                        </div>
                        <div
                            className={
                                prisma && width > 800
                                    ? styles.storeDesktopPrisma
                                    : exo
                                    ? styles.storeDesktopExo
                                    : felfort
                                    ? styles.storeDesktopFelfort
                                    : styles.storeDesktop
                            }
                        >
                            <div className={styles.imgDesktop}>
                                <img
                                    className={styles.imageStore}
                                    src={item.imgDesktop}
                                    alt="imageStore"
                                />
                            </div>
                            { !prisma &&
                            <div
                                className={
                                    felfort
                                        ? styles.imgPhonesFelfort
                                        : exo
                                        ? styles.imgPhonesExo
                                        : vodafone
                                        ? styles.imgPhonesVodafone
                                        : storeNewsan
                                        ? styles.imgPhonesStoreNewsan
                                        : styles.imgPhones
                                }
                            >
                                <img
                                    className={styles.imageStore}
                                    src={item.imgPhones}
                                    alt="imageStore"
                                />
                            </div>
                            }
                            <div
                                className={
                                    felfort && width > 800
                                        ? styles.storeTitleFelfort
                                        : styles.storeTitle
                                }
                            >
                                <h3>{item.title}</h3>
                                <p>{item.text}</p>
                            </div>
                            {(felfort || vodafone) && width > 800 && (
                                <div
                                    className={
                                        vodafone
                                            ? styles.methodologyVodafone
                                            : styles.methodologyFelfort
                                    }
                                >
                                    <p>{textMethodology}</p>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
            <div
                className={
                    (felfort || vodafone) && width > 800
                        ? styles.displayNone
                        : styles.methodology
                }
            >
                <p>{textMethodology}</p>
            </div>
            <div
                className={
                    prisma && width < 800 ? styles.sliderPrisma : styles.slider
                }
            >
                <Slider {...settings}>
                    {methodology.map((item, index) => {
                        return (
                            <div
                                className={
                                    prisma
                                        ? styles.sliderContentPrisma
                                        : styles.sliderContent
                                }
                                key={index}
                            >
                                <img
                                    className={
                                        prisma
                                            ? styles.imgSliderPrisma
                                            : styles.imgSlider
                                    }
                                    src={item.img}
                                    alt="imgMethodology"
                                    height={75}
                                />
                                <h3>{item.title}</h3>
                                {item.description.map((description, index) => {
                                    return (
                                        <li
                                            className={
                                                prisma
                                                    ? styles.liPrisma
                                                    : styles.li
                                            }
                                            key={index}
                                        >
                                            {description}
                                        </li>
                                    );
                                })}
                            </div>
                        );
                    })}
                </Slider>
            </div>
            {width > 800 && (
                <div
                    className={
                        felfort
                            ? styles.methodologyRootFelfort
                            : prisma
                            ? styles.methodologyRootPrisma
                            : vodafone
                            ? styles.methodologyRootVodafone
                            : styles.methodologyRoot
                    }
                >
                    {methodology.map((item, index) => {
                        return (
                            <div
                                className={
                                    felfort
                                        ? styles.methodologyContentFelfort
                                        : vodafone
                                        ? styles.methodologyContentVodafone
                                        : styles.methodologyContent
                                }
                                key={index}
                            >
                                <img
                                    className={
                                        item.title === 'TECNOLOGÍAS'
                                            ? styles.imgTechnologies
                                            : vodafone
                                            ? styles.imgMethodologyVodafone
                                            : styles.imgMethodology
                                    }
                                    src={item.img}
                                    alt="imgMethodology"
                                />
                                <h3>{item.title}</h3>
                                <div>
                                    {item.description.map(
                                        (description, index) => {
                                            return (
                                                <li
                                                    className={
                                                        vodafone
                                                            ? styles.liMethodologyVodafone
                                                            : styles.liMethodology
                                                    }
                                                    key={index}
                                                >
                                                    {description}
                                                </li>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            {vodafone && (
                <div className={styles.rootVodafone}>
                    <h3 className={styles.otherProjectsTitleVodafone}>
                        {otherProjectsText}
                    </h3>
                    {buttons.map((item, index) => {
                        return (
                            <div className={styles.vodafoneButton} key={index}>
                                <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button
                                        className={styles.containerVodafone}
                                    >
                                        <div className={styles.addedText}>
                                            VISITAR SITIO
                                        </div>
                                        <img
                                            className={styles.imgButton}
                                            src={iconButton}
                                            alt="iconButton"
                                        />
                                    </button>
                                </a>
                            </div>
                        );
                    })}
                </div>
            )}
            {!(vodafone || prisma) && (
                <div
                    className={
                        felfort || storeNewsan
                            ? styles.otherProjectsFelfort
                            : newsanMagento
                            ? styles.otherProjectsNewsanMagento
                            : exo
                            ? styles.otherProjectsExo
                            : styles.otherProjects
                    }
                >
                    <div
                        className={
                            gpsFarma
                                ? styles.buttonsRootGpsFarma
                                : newsanMagento
                                ? styles.buttonsRootNewsanMagento
                                : exo
                                ? styles.buttonsRootExo
                                : styles.buttonsRoot
                        }
                    >
                        <div
                            className={
                                newsanMagento
                                    ? styles.buttonsOtherProjectsNewsanMagento
                                    : (exo || felfort) && width > 800
                                    ? styles.buttonsOtherProjectsExo
                                    : (storeNewsan || gpsFarma) && width > 800
                                    ? styles.buttonsOtherProjectsStoreNewsan
                                    : styles.buttonsOtherProjects
                            }
                        >
                            <h3>{otherProjectsText}</h3>
                            <div
                                className={
                                    newsanMagento
                                        ? styles.buttonsContainerNewsanMagento
                                        : styles.buttonsContainer
                                }
                            >
                                {buttons.map((item, index) => {
                                    return (
                                        <div
                                            className={
                                                newsanMagento
                                                    ? styles.bRootNewsanMagento
                                                    : (storeNewsan ||
                                                          gpsFarma) &&
                                                      width > 800
                                                    ? styles.bRootStoreNewsan
                                                    : styles.bRoot
                                            }
                                            key={index}
                                        >
                                            <a
                                                href={item.url}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <button
                                                    className={
                                                        newsanMagento
                                                            ? styles.containerNewsanMagento
                                                            : styles.container
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            newsanMagento
                                                                ? styles.addedTextNewsanMagento
                                                                : styles.addedText
                                                        }
                                                    >
                                                        {newsanMagento &&
                                                        width < 800
                                                            ? ''
                                                            : textButton}
                                                        {newsanMagento
                                                            ? item.buttonText
                                                            : ''}
                                                    </div>
                                                    <img
                                                        className={
                                                            styles.imgButton
                                                        }
                                                        src={iconButton}
                                                        alt="iconButton"
                                                    />
                                                </button>
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div
                            className={
                                gpsFarma
                                    ? styles.containerImgMobileAndDesktopGpsFarma
                                    : exo && width > 800
                                    ? styles.containerImgMobileAndDesktopExo
                                    : felfort && width > 800
                                    ? styles.containerImgMobileAndDesktopFelfort
                                    : newsanMagento
                                    ? styles.containerImgMobileAndDesktopNewsanMagento
                                    : storeNewsan && width > 800
                                    ? styles.containerImgMobileAndDesktopStoreNewsan
                                    : exo && width < 800
                                    ? styles.containerImgMobileAndDesktopExoMobile
                                    : felfort && width < 800
                                    ? styles.containerImgMobileAndDesktopFelfortMobile
                                    : styles.containerImgMobileAndDesktop
                            }
                        >
                            <img
                                className={
                                    gpsFarma
                                        ? styles.imgMobileAndDesktopGpsFarma
                                        : exo
                                        ? styles.imgMobileAndDesktopExo
                                        : styles.imgMobileAndDesktop
                                }
                                src={
                                    (gpsFarma || exo || felfort) && width < 800
                                        ? otherProjects.imgOnlyMobile
                                        : otherProjects.imgMobileAndDesktop
                                }
                                alt={otherProjects.altImgMobileAndDesktop}
                            />
                        </div>
                    </div>
                    <div>
                        {width > 800 && !(gpsFarma || exo) && width > 800 && (
                            <div
                                className={
                                    felfort
                                        ? styles.containerImgDesktopRightFelfort
                                        : storeNewsan
                                        ? styles.containerImgDesktopRightStoreNewsan
                                        : newsanMagento
                                        ? styles.containerImgDesktopRightNewsanMagento
                                        : exo
                                        ? styles.containerImgDesktopRightExo
                                        : styles.containerImgDesktopRight
                                }
                            >
                                <img
                                    className={
                                        felfort
                                            ? styles.imgDesktopRightFelfort
                                            : styles.imgDesktopRight
                                    }
                                    src={otherProjects.imgDesktopRight}
                                    alt={otherProjects.altImgDesktopRight}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Landing;
