import React from 'react'
import styles from './homePageEcommerce.module.css'
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';
import homeImgDesktop from '../../assets/footerDesktop.svg';
import desktopImage from '../../assets/desktopHome.svg';
import magento from '../../assets/magento_logo.png';
import mercadoShops from '../../assets/mercado-shops_logo.png';
import vtex from '../../assets/vtex_logo.png';
import wooEcommerce from '../../assets/woo-commerce_logo.png';
import SocialNetworks from '../HomePage/RenderHomePage/SocialNetworks/socialNetworks';

const HomePageEcommerce = () => {
    const [ store ] = useContext(StoreContext);
    
    const subTitle = store ? "WE ARE A BOUTIQUE AGENCY" : "SOMOS UNA AGENCIA BOUTIQUE";
    const title = store ? <p className={styles.title}>We provide personalized and quality <span>ecommerce</span> solutions and consulting</p> : <p className={styles.title}>Brindamos soluciones y consultoría de <span>ecommerce</span> personalizadas y de calidad</p>;

    return (
        <div className={styles.container}>
            <div className={styles.socialNetworks}>
                <SocialNetworks/>
            </div>
            <div className={styles.titleAndImage}>
                <div>
                    <p className={styles.subTitle}>{subTitle}</p>
                    {title}
                </div>
                <img src={desktopImage} alt="ecommerce icon" className={styles.desktopImage}/>
            </div>
            <img src={homeImgDesktop} alt="ecommerce icon" className={styles.homeImgDesktop}/>
            <div className={styles.footerMobile}>
                <div className={styles.images}>
                    <img src={magento} alt="ecommerce icon" className={styles.footerImg}/>
                    <img src={vtex} alt="ecommerce icon" className={styles.footerImg}/>
                </div>
                <div className={styles.images}>
                    <img src={mercadoShops} alt="ecommerce icon" className={styles.footerImg}/>
                    <img src={wooEcommerce} alt="ecommerce icon" className={styles.footerImg}/>
                </div>
            </div>
        </div>
    )
}

export default HomePageEcommerce