import iconLogo from '../../../../assets/logoNewsanLanding.svg';
import imgNewsan from '../../../../assets/imgNewsanLanding.png';
import iconTeam from '../../../../assets/equipo.gif';
import iconSprintDuration from '../../../../assets/duracion.gif';
import iconProcess from '../../../../assets/proceso.gif';
import iconTechnology from '../../../../assets/magento.gif';
import imgNewsanDesktop from '../../../../assets/imgNewsanDesktop.png';
import imgPhonesNewsan from '../../../../assets/imgPhonesNewsanDesktop.png';
import imgMobileAndDesktop from '../../../../assets/imgPhilco.png';
import imgNewsanB2b from '../../../../assets/imgNewsanB2b.png';
import imgMagentoPhonos from '../../../../assets/imgMagentoPhonos.png';
import imgDesktopRight from '../../../../assets/imgNoblex.png';
import { useContext } from 'react';
import { StoreContext } from '../../../Store/storeProvider';

export const useSampleData = (props) => {
    const [store] = useContext(StoreContext);

    const technology = [
        {
            img: iconLogo,
            title: 'MAGENTO 2',
            subtitle: store ? (
                <>
                    <h4>ONLINE STORES |</h4>
                    <p>Newsan Group</p>
                </>
            ) : (
                <>
                    <h4>TIENDAS ONLINE |</h4>
                    <p>Grupo Newsan</p>
                </>
            ),
            text: store
                ? 'Design, development and migration of all its online stores (Philco, Atma, Noblex, Siam) to Magento2. We provide the same Delivery & Support service for your B2B store. In addition, we manage all your production, staging and development environments (~50 servers).'
                : 'Diseño, desarrollo y migración de todas sus tiendas online (Philco, Atma, Noblex, Siam) a Magento2. Brindamos el mismo servicio de Delivery & Support para su tienda B2B. Además, manejamos todos sus entornos productivos, staging y desarrollo (~50 servidores).'
        }
    ];

    const buttons = [
        { buttonText: 'PHILCO', url: 'https://philco.com.ar' },
        { buttonText: 'NOBLEX', url: 'https://noblex.com.ar' },
        { buttonText: 'ATMA', url: 'https://atma.com.ar' },
        { buttonText: 'SIAM', url: 'https://siam.com.ar' }
    ];

    const storeNewsan = [
        {
            title: store ? 'ONLINE STORES' : 'TIENDAS ONLINE',
            text: store
                ? 'Together with the IT and eCommerce team, we managed to stabilize the stores they had. After a lot of joint work, the migration of all brands to Magento was carried out with the possibility that each one can sell online.'
                : 'Junto al equipo de IT y de eCommerce logramos estabilizar las tiendas con las que contaban. Después de mucho trabajo en conjunto se realizó la migración de todas las marcas a Magento con la posibilidad de que cada una pueda vender online.',
            img: imgNewsan,
            imgDesktop: imgNewsanDesktop,
            imgPhones: imgPhonesNewsan
        }
    ];

    const textMethodology = store
        ? "AS WE DO IT FOR EACH PROJECT, WE WORK UNDER THE SCRUM METHODOLOGY, COLLABORATIVELY AND AS A TEAM TO OBTAIN THE BEST POSSIBLE RESULT OF THE PROJECT'"
        : 'COMO LO HACEMOS PARA CADA PROYECTO TRABAJAMOS BAJO LA METODOLOGÍA SCRUM, COLABORATIVAMENTE Y EN EQUIPO PARA OBTENER EL MEJOR RESULTADO POSIBLE DEL PROYECTO';

    const methodology = [
        {
            img: iconTeam,
            title: store ? 'TEAM ' : 'EQUIPO',
            description: store
                ? ['1 designers UX / UI', '3 developers', '1 p.m ', '1 QA ']
                : [
                      '1 diseñadora UX / UI',
                      '3 desarrolladores',
                      '1 p.m ',
                      '1 control de calidad '
                  ]
        },
        {
            img: iconSprintDuration,
            title: store ? 'SPRINT DURATION' : 'DURACIÓN DEL SPRINT',
            description: store
                ? [
                      '6 sprints from inception to fully response product delivery'
                  ]
                : [
                      '6 sprints desde el inicio hasta la entrega del producto totalmente response'
                  ]
        },
        {
            img: iconProcess,
            title: store ? 'PROCESS' : 'PROCESO',
            description: store
                ? [
                      'Evaluation of the product, its user and its competition, creation of the information architecture and selection of the type of technology to use'
                  ]
                : [
                      'Evaluación del producto, su usuario y su competencia, creación de la arquitectura de la información y selección del tipo de tecnología a usar'
                  ]
        },
        {
            img: iconTechnology,
            title: store ? 'TECHNOLOGY' : 'TECNOLOGÍA',
            description: store
                ? ['Magento is a modern cloud e-commerce platform.']
                : [
                      'Magento es una moderna plataforma de comercio electrónico en la nube.'
                  ]
        }
    ];

    const otherProjectsText = store
        ? 'LEARN MORE ABOUT THESE PROJECTS:'
        : 'CONOCÉ MÁS ACERCA DE ESTOS PROYECTOS:';

    const b2bTitle = store ? 'B2B STORE' : 'TIENDA B2B';

    const b2bText =
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

    const otherProjects = {
        imgMobileAndDesktop,
        imgMagentoPhonos,
        imgNewsanB2b,
        imgDesktopRight,
        altImgMobileAndDesktop: 'imgPhilco',
        altImgDesktopRight: 'imgNoblex'
    };

    const newsanMagento = true;

    return {
        technology,
        buttons,
        storeNewsan,
        textMethodology,
        methodology,
        otherProjects,
        otherProjectsText,
        b2bText,
        b2bTitle,
        newsanMagento
    };
};
