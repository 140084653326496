import React, { useContext } from 'react';
import styles from './lenguageButton.module.css';
import { StoreContext } from '../../Store/storeProvider';
import { types } from '../../Store/storeReducer'

const LenguageButton = () => {
    const [ store, dispatch ] = useContext(StoreContext);
  
    return (
        <div className={styles.root}>
            <button 
                className={store ? styles.enButton : styles.esButton} 
                onClick={()=> dispatch({type: types.spanish})}>
                es
            </button>
            <p>|</p>
            <button 
                className={store ? styles.esButton : styles.enButton} 
                onClick={()=> dispatch({type: types.english})}>
                en
            </button>
        </div>
    )
}

export default LenguageButton
