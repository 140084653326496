import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import logo from '../../../assets/whiteLogo.svg';
import close from'../../../assets/close.svg';
import contactImage from '../../../assets/contactIcon.svg';
import LenguageButton from '../LenguageButton/lenguageButton';
import styles from './dropdownMenu.module.css';
import { useContext } from 'react'; 
import { StoreContext } from '../../Store/storeProvider';

const DropdownMenu = (props)=> {
    const [width, setWidth] = useState(window.innerWidth);

    const {
        methodology = false,
        weAre = false,
        weMake = false,
        contact = false,
        theSayAboutUs = false,
        projects = false,
        customer = false,
        setShowMenu,
        showMenu,
        isEcommerce,
        handleLinkClick
    } = props;

    const [ store ] = useContext(StoreContext);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);

    const isMobile = width < 800;

    const items = [
        {title:  store ? "methodology" : "metodología", path: methodology, top: isMobile ? 8100 : 4200},
        {title:  store ? "Projects" : "proyectos", path: projects, top: isMobile ? 3580 : 2600},
        {title:  store ? "they say about us" : "dicen de nosotros", path: theSayAboutUs, top: isMobile ? 3000 : 2250},
        {title:  store ? "we make" : "hacemos", path: weMake, top: isMobile ? 2100 : 1900},
        {title:  store ? "we are" : "somos", path: weAre, top: isMobile ? 500 : 700},
        {title:  store ? "customers" : "clientes", path: customer, top: isMobile ? 10000 : 3800},
        {title:  store ? "contact" : "contacto", path: contact, top: 10000}
    ];

    const itemsEcommerce = [
        {title:  store ? "tecnology" : "tecnologia", top: 600},
        {title:  store ? "Projects" : "proyectos", top: 1200},
        {title:  store ? "ask for budget" : "pedir presupuesto", top: 3940},
        {title:  store ? "contact" : "contacto", top: 3940}
    ];

    const scrollToSection = (elementRef, top, name) => {
        setShowMenu(!showMenu);

        if( isMobile &&
            !elementRef && 
            !elementRef.current && 
            (name === 'Projects' || name === 'proyectos' || name === 'they say about us' || name === 'dicen de nosotros')
        ) {
            setTimeout(()=> {
                window.location.reload()
            }, 800)
        }

        window.scrollTo({
            top: (elementRef && elementRef.current) ? elementRef.current.offsetTop : top,
            behavior: 'smooth',
        })
    };

    const handleEcommerce = (top)=> {
        setShowMenu(!showMenu);
        handleLinkClick(top);
    }

    return (
        <div className={!showMenu ? styles.newContainer : styles.container}> 
            <div className={styles.header}>
                <img 
                    src={logo}
                    className={styles.logo}
                    alt="IURCO"
                    onClick={()=>{setShowMenu(!showMenu)}} 
                />
                <img 
                    src={close}
                    className={styles.closeImg}
                    alt="close" 
                    onClick={()=>{setShowMenu(!showMenu)}}
                />
            </div>
            { !isEcommerce ?
                <div className={styles.languageContainer}>
                    <LenguageButton/>
                </div> : null
            }
            {
                isEcommerce ? 
                    <div className={styles.itemsEcommerce}> 
                        {   itemsEcommerce.map((item, index) => {
                                return (
                                    <div key={index} className={(item.title === 'contacto' || item.title === 'contact') && styles.root}>
                                        <Link  
                                            onClick={()=> handleEcommerce(item.top)}
                                            className={styles.link}
                                        >
                                            <p className={styles.item}>
                                                {item.title}
                                            </p>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                        <div className={styles.languageContainerEcommerce}>
                            <LenguageButton/>
                        </div>
                    </div> :
                <div className={styles.items}> 
                {   items.map((item, index) => {
                        return (
                            <div key={index} className={(item.title === 'contacto' || item.title === 'contact') && styles.root}>
                                {   (item.title === 'contacto' || item.title === 'contact') && 
                                    <img 
                                        onClick={()=>{setShowMenu(!showMenu)}} 
                                        src={contactImage} 
                                        className={styles.contactImage} 
                                        alt="contact"
                                    />
                                }
                                <Link  
                                    to="/"
                                    onClick={()=> scrollToSection(item.path, item.top, item.title)}
                                    className={styles.link}
                                >
                                    <p className={(item.title === 'contacto' || item.title === 'contact') ? styles.contact : styles.item}>
                                        {item.title}
                                    </p>
                                </Link>
                            </div>
                        )
                    })
                }
                </div>
            }
        </div>
    )
}

export default DropdownMenu;