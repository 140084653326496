import React, { Fragment } from 'react';
import useViewportWidth from '../../talons/ViewportWidth/useViewportWidth';
import styles from './info.module.css';
import InfoImages from './InfoImages/infoImages';
import { useSampleData } from './sampleData';

const Info = () => {
    const width = useViewportWidth();
    const isMobile = width < 800;
    const { text, mobileQuotesImages, desktopQuoteImages } = useSampleData();

    return (
        <Fragment>
            <div className={styles.rootContainer}>
                <div className={styles.root}>
                    <img 
                        src={mobileQuotesImages.opening} 
                        alt="opening quote icon"
                        className={styles.openingQuoteMobile}
                    />
                    <img 
                        src={desktopQuoteImages.opening} 
                        alt="opening quote icon"
                        className={styles.openingQuote}
                    />
                    <p className={styles.text}>
                        {text}
                    </p>
                    <img 
                        src={mobileQuotesImages.closing} 
                        alt="closing quote icon"
                        className={styles.closingQuoteMobile}
                    />
                    <img 
                        src={desktopQuoteImages.closing} 
                        alt="closing quote icon"
                        className={styles.closingQuote}
                    />
                </div>
                <InfoImages isMobile={isMobile}/>
            </div>
        </Fragment>
    )
}

export default Info
