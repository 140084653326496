import { useState, useCallback } from 'react';

const browser = typeof window !== 'undefined';

export default function useViewportWidth() {
    const [width, setWidth] = useState(browser ? window.innerHeight : 0);

    const setSize = useCallback(() => {
        setWidth(window.innerWidth || 0);
    }, []);

    window.addEventListener('load', setSize, { passive: true });
    window.addEventListener('resize', setSize, { passive: true });
    window.addEventListener('orientationchange', setSize, { passive: true });

    return width;
}