import { createContext, useReducer } from 'react';
import StoreReducer, { defaultLenguage } from './storeReducer'

const StoreContext = createContext();

const StoreProvider = ({ children }) => {
    const [ store, dispatch ] = useReducer(StoreReducer, defaultLenguage);
   
    return (
        <StoreContext.Provider value={[ store, dispatch ]}>
            {children}
        </StoreContext.Provider>
    )
}

export { StoreContext };
export default StoreProvider;