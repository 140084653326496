import React from 'react'
import styles from './ecommerce.module.css';
import Header from '../Header/header';
import HomePageEcommerce from '../HomePageEcommerce/homePageEcommerce';
import WeMakeEcommerce from '../WeMakeEcommerce/weMakeEcommerce';
import OurProjects from '../OurProjects/ourProjects';
import CustomerLogos from '../CustomerLogos/customerLogos';
import WorkTogether from '../WorkTogether/workTogether';
import Ubication from '../Ubication/ubication';
import ContactForm from '../ContactForm/contactForm';
import Footer from '../Footer/footer';

const Ecommerce = () => {
    return (
       <div className={styles.root}>
            <Header isEcommerce={true}/>
            <HomePageEcommerce/>
            <WeMakeEcommerce/>
            <OurProjects isEcommercePage={true}/>
            <CustomerLogos/>
            <WorkTogether/>
            <div className={styles.contactFormAndUbication}>
                <div className={styles.contactFormRoot}>
                    <ContactForm/>
                </div>
                <Ubication/>    
            </div>
            <Footer isEcommercePage={true}/>
       </div> 
   )
}

export default Ecommerce  