import React from 'react'
import styles from './opinions.module.css';
import SliderOpinions from './sliderOpinions';
import { useSampleData } from './sampleData';
import "slick-carousel/slick/slick.css";
import "../Slick-carousel/slick-theme.css";
import Slider from "react-slick";

const Opinions = () => {
    const {title, items, openIcon, closeIcon } = useSampleData();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScrol: 1
    }

    return (
        <div>
            <SliderOpinions/>
            <div className={styles.root}>
                <p className={styles.title}>{title}</p>
                <div className={styles.contentSlider}>
                    <Slider {...settings}>
                        {items.map((item, index) => {
                            return (
                                <div key={index} className={styles.item}>
                                    <div className={styles.openCloseIconAndtext}>
                                        <img 
                                            src={openIcon}
                                            alt="icon"                     
                                            height={90} 
                                            width={90}
                                        />
                                        <p className={styles.since}>{item.since}</p>
                                    </div>
                                    <div style={{backgroundImage: `url(${item.ImgBackground})`}} className={styles.textRoot}>
                                        <p className={styles.text}>{item.text}</p>
                                        <p className={styles.owner}>
                                            {item.owner}
                                            <span className={styles.client}> {item.client}</span>
                                        </p>
                                    </div>
                                    <img   
                                        src={closeIcon}
                                        alt="icon" 
                                        height={35} 
                                        width={35} 
                                        className={styles.closeIcon}
                                    />
                                </div>
                            )
                        })}
                    </Slider>   
                </div>
            </div>
        </div>
    )
}

export default Opinions