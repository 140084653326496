import React from 'react'
import Landing from '../landing';
import Ubication from '../../Ubication/ubication';
import WorkTogether from '../../WorkTogether/workTogether';
import OurProjects from '../../OurProjects/ourProjects';
import styles from './felfortPage.module.css';
import imgBannerDesktop from '../../../assets/imgStoreNewsanBanner.png';
import { useSampleData } from './sampleData';
import ContactForm from '../../ContactForm/contactForm';
import Footer from '../../Footer/footer';

const FelfortPage = () => {
    const {  
        technology,
        buttons,
        storeFelfort,
        textMethodology,
        otherProjectsText,
        otherProjects,
        felfort,
        methodologyFelfort 
    } = useSampleData();

    return (
        <div>
            <Landing 
                item={technology}
                buttons={buttons}
                store={storeFelfort}
                methodology={methodologyFelfort}
                textMethodology={textMethodology}
                otherProjects={otherProjects}
                otherProjectsText={otherProjectsText}
                felfort={felfort}
                imgBannerDesktop={imgBannerDesktop}
            />
            <div className={styles.root}>
                <OurProjects landing={true}/>
                <WorkTogether/>
                <div className={styles.contactFormAndUbication}>
                    <div className={styles.contactFormRoot}>
                        <ContactForm/>
                    </div>                
                    <Ubication/>
                </div>
                <Footer/>
            </div>
        </div>
    )
}

export default FelfortPage