import React from 'react'
import styles from './customerLogos.module.css';
import { useSampleData } from './sampleData';

const CustomerLogos = () => {
    const { data, title, dataDesktop1, dataDesktop2} = useSampleData();

    return (
        <div className={styles.root}>
            <div className={styles.titleRoot}>
                <h3 className={styles.title}>{title}</h3>
            </div>
            <div className={styles.container}>
                {data.map((item,index) => {
                    return (
                        <img
                            className={(item.name === "tiendaExo" || item.name === "newsan" || item.name === "movistar" || item.name === "noblex" || item.name === "redBull")  ? styles.imgExo : styles.imgSize}
                            src={item.img}
                            alt='customerLogos'
                            key={index}
                        />
                    )
                })}
            </div>
            <div className={styles.containerDesktop}>
                <div className={styles.containerDesktop1}>
                    {dataDesktop1.map((item,index) => { 
                        return (
                            <img
                                className={item.name === 'unilever' || item.name === 'quaker' ? styles.imgSmall : styles.imgSize}
                                src={item.img}
                                alt='customerLogos'
                                key={index}
                            />
                        )
                    })}
                </div>
                <div className={styles.containerDesktop2}>
                    {dataDesktop2.map((item,index) => {
                        return (
                            <img
                                className={item.name === 'tiendaExo' ? styles.imgExo : styles.imgSize}
                                src={item.img}
                                alt='customerLogos'
                                key={index}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default CustomerLogos;