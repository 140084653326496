import { useState } from 'react';
import { useSampleData } from './sampleData';
import styles from './packs.module.css';
import Item from './item';

const Packs = ()=> {
    const { title, iconButton, items, itemsRowBottom, itemsRowTop } = useSampleData()
    const [ openAccordion, setOpenAccordion ] = useState(null);

    const handleAccordion = accordionNumber => {
        setOpenAccordion(
            openAccordion === accordionNumber 
            ? null 
            : accordionNumber
        )
    }

    return (
        <div className={styles.generalRoot}>
            <div className={styles.root}>
                <p className={styles.title}>{title}</p>
                <div className={styles.items}>
                    {
                        items.map((item,index)=> {
                            return (
                                <Item 
                                    item={item}
                                    index={index}
                                    iconButton={iconButton}
                                    openAccordion={openAccordion}
                                    handleAccordion={handleAccordion}
                                    key={index}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <div className={styles.rootDesktop}>
                <p className={styles.title}>{title}</p>
                <div className={styles.items}>
                    {
                        itemsRowTop.map((item,index)=> {
                            return (
                                <Item 
                                    item={item}
                                    index={item.id}
                                    iconButton={iconButton}
                                    openAccordion={openAccordion}
                                    handleAccordion={handleAccordion}
                                    key={index}
                                    isRow={true}
                                />
                            )
                        })
                    }
                </div>
                <div className={styles.items}>
                    {
                        itemsRowBottom.map((item,index)=> {
                            return (
                                <Item 
                                    item={item}
                                    index={index}
                                    iconButton={iconButton}
                                    openAccordion={openAccordion}
                                    handleAccordion={handleAccordion}
                                    key={index}
                                    isRow={true}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Packs;