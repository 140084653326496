import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useViewportWidth from '../../../../talons/ViewportWidth/useViewportWidth';
import Landing from '../landing';
import styles from './gpsFarma.module.css';
import Ubication from '../../../Ubication/ubication';
import WorkTogether from '../../../WorkTogether/workTogether';
import OurProjects from '../../../OurProjects/ourProjects';
import { useSampleData } from './sampleData';
import ContactForm from '../../../ContactForm/contactForm';
import Footer from '../../../Footer/footer';

const GpsFarmaPageEcommerce = () => {
    const {
        technology,
        buttons,
        storeGps,
        textMethodology,
        otherProjectsText,
        otherProjects,
        gpsFarma,
        ape,
        methodologyGpsFarma 
    } = useSampleData();

    const width = useViewportWidth();

    return (
        <div>
            <Landing 
                item={technology}
                buttons={buttons}
                store={storeGps}
                methodology={methodologyGpsFarma}
                textMethodology={textMethodology}
                otherProjects={otherProjects}
                otherProjectsText={otherProjectsText}
                gpsFarma={gpsFarma}
                ape={ape}
            />
            <div className={styles.ape}>
                <div className={styles.titleAndText}>
                    <h3>{ape.title}</h3>
                    <p>{ape.text}</p>
                </div>
                <div className={styles.methodology}>
                    {   
                        methodologyGpsFarma.map((item, index) => {
                            return (
                                <div className={styles.methodologyContent} key={index}>
                                    <img
                                        className={styles.imgMethodology}
                                        src={item.img}
                                        alt='imgMethodology'
                                    />
                                    <h4 className={styles.methodologyTitle}>{item.title}</h4>
                                    {
                                        item.description.map((description, index) => {
                                            return (
                                                <li className={styles.li} key={index}>
                                                    {description}
                                                </li>
                                            )
                                        })
                                    }    
                                </div>
                            )
                        })
                    }
                </div>
                <div className={styles.images}>
                    <img
                        src={width > 800 ? ape.imgApe : ape.imgApeMobile}
                        alt='imgGpsFarma'
                        className={styles.imgApe}
                    />
                    <div className={styles.containerApePhono}>
                        <img
                            src= {ape.imgApePhono} 
                            alt='imgGpsFarma'
                            className={styles.imgApePhono}
                        />
                    </div>
                </div>
            </div>
            <OurProjects landing={true} isEcommercePage={true}/>
            <WorkTogether/>
            <div className={styles.contactFormAndUbication}>
                <div className={styles.contactFormRoot}>
                    <ContactForm/>
                </div>
                <Ubication/>
            </div>
            <Footer/>
        </div>
    )
}

export default GpsFarmaPageEcommerce