import React from 'react';
import { useState } from 'react';
import styles from './ourProjects.module.css';
import { useSampleData } from './sampleData';
import iconButtonDesktop from '../../assets/iconButtonLanding.svg';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { StoreContext } from '../Store/storeProvider';

const OurProjects = (props) => {
    const {landing, isEcommercePage} = props;
    const [ store ] = useContext(StoreContext);
    const {
        title,
        textButton,
        data,
        iconButton,
        iconButtonSeeMoreProjects,
        textButtonSeeMoreProjects,
        textButtonSeeLessProjects,
        allData,
        titleDesktop,
        dataDesktopOne,
        dataDesktopTwo,
        imgDesktop
    } = useSampleData();

    const [items, setItems] = useState(data);
    const [itemsDesktop, setItemsDesktop] = useState(dataDesktopOne);

    const handleClick = () => {
        if(items.length === data.length) {
            setItems(allData);
        } else {
            setItems(data)
        }
    }

    const handleLinkClick = (top) => {
        window.scrollTo({
            top: top,
            behavior: 'smooth',
        })
    };    

    const handleButtonDesktop = () => {
        const firstElementUrl = itemsDesktop[0].url;

        if(firstElementUrl === '/exo-store') {
            setItemsDesktop(dataDesktopTwo);
        } else {
            setItemsDesktop(dataDesktopOne)
        }    
    }

    const buttonsDesktop = (
        <div className={styles.buttonsContainer}>
            <button
                className={styles.textButtonDesktop}
                onClick={handleButtonDesktop}
            >
                PREV
            </button>
            <button
                className={styles.textButtonDesktop}
                onClick={handleButtonDesktop}
            >
                NEXT
            </button>
        </div>
    )

    const desktopVersion = 
        <div className={styles.rootDesktop}>
            { !landing ?
                <div>
                    <h3>{titleDesktop}</h3>
                    <img
                        src={imgDesktop}
                        width= {200}
                        height= {185}
                        className={styles.imgTitle}
                        alt="icon"
                    />
                </div> : null
            }
            <div className={styles.buttonsAndItems}>
                {buttonsDesktop}
                <div className={styles.OurProjectsDesktop}>
                    {   itemsDesktop.map((item, index) => {
                            return (
                                <div 
                                    key={index}
                                    style={{
                                        backgroundImage:`url(${item.img})`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        backgroundOrigin: "border-box"
                                    }}
                                    className={item.id === 7 ? styles.containerDesktopNewsan : styles.containerDesktop}
                                >
                                    <img
                                        src={item.imgHover}
                                        alt="icon"
                                        width={500}
                                        height={500}
                                        className={styles.imgProjectsDesktopHover} 
                                    />
                                    {store ? item.englishText : item.spanishText}
                                    <Link to={isEcommercePage ? item.urlEcommerce : item.url} className={styles.link} onClick={()=> handleLinkClick(0)}>
                                        {textButton}
                                        <img
                                            src={iconButtonDesktop}
                                            width={10}
                                            height={10}
                                            className={styles.iconButtonDesktop}
                                            alt="icon"
                                        />
                                    </Link>
                                </div>
                            )
                        })}
                </div>
                {buttonsDesktop}
            </div>
        </div>
    
    return (
        <div>
            {desktopVersion}
            <div className={styles.rootMobile}>
                {!landing ? <h3 className={styles.title}>{title}</h3> : null}
                    {
                        items.map((item, index) => {
                            return (
                                <div className={styles.box} style={item.color} key={index}>                
                                    {
                                        item.images.map((img,index) => {
                                            return (
                                                <div className={styles.container} key={index}>
                                                    <img className={styles.img}
                                                        src={img} 
                                                        alt= "icon"
                                                    />
                                                </div> 
                                            )
                                    })}
                                    <Link to={isEcommercePage ? item.urlEcommerce : item.url} className={styles.linkMobile} onClick={()=> handleLinkClick(0)}>
                                        <p>{textButton}</p>  
                                        <img 
                                            className= {styles.iconButtonSeeMore}
                                            src={iconButton} 
                                            alt= "icon"
                                        />
                                    </Link>
                                </div>
                            )
                        }
                    )}
                <button className={styles.button} onClick={handleClick}>
                    <div className={styles.buttonRoot}>
                        <p>{items.length === data.length ? textButtonSeeMoreProjects : textButtonSeeLessProjects}</p>
                        <img 
                            className={items.length === data.length ? styles.iconButton : styles.iconButtonRotate}
                            src={iconButtonSeeMoreProjects} 
                            alt= "icon"
                        />
                    </div>
                </button>
            </div>
        </div>
    )
}

export default OurProjects
