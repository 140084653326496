import React, { useReducer, useState, useEffect, useMemo, useRef } from 'react';
import styles from './homePage.module.css';
import RenderHomePage from './RenderHomePage/renderHomePage';
import ecommerce from '../../assets/ecommerce.svg';
import pwa from '../../assets/pwa.svg';
import webSite from '../../assets/webSite.svg';
import uxuiDesign from '../../assets/uxui.svg';
import Pagination from './Pagination/pagination';
import AboutAs from '../AboutAs/aboutAs';
import Info from '../Info/info';
import Hubs from '../Hubs/hubs';
import WorkProcess from '../WorkProcess/workProcess';
import Technologies from '../Technologies/technologies';
import OurProjects from '../OurProjects/ourProjects';
import Opinions from '../Opinions/opinions';
import Methodology from '../Methodology/methodology';
import CustomerLogos from '../CustomerLogos/customerLogos';
import ProjectProcess from '../ProjectProcess/projectProcess';
import WorkTogether from '../WorkTogether/workTogether';
import Ubication from '../Ubication/ubication';
import ContactForm from '../ContactForm/contactForm';
import { useContext } from 'react'; 
import { StoreContext } from '../Store/storeProvider';
import Header from '../Header/header';
import Packs from '../Packs/packs';
import Footer from '../Footer/footer';
import SocialNetworks from './RenderHomePage/SocialNetworks/socialNetworks'

const HomePage = () => {
    const [ page, setPage ] = useState(1)
    const [ store ] = useContext(StoreContext);

    const weAre = useRef(null);
    const weMake = useRef(null);
    const theSayAboutUs = useRef(null);
    const projects = useRef(null);
    const methodology = useRef(null);
    const customer = useRef(null);
    const contact = useRef(null);

    const types = useMemo(() => {
        const defaultTypes = {
            one: 'one',
            two: 'two',
            three:'three',
            four:'four'
        }
        return defaultTypes
    }, [])
    
    const initialState = <RenderHomePage text={'ecommerce'} img={ecommerce}/>
    
    const reducer = (state, action) => {
        switch(action.type) {
            case(types.one):
                return <RenderHomePage text={'ecommerce'} img={ecommerce}/>
    
            case(types.two):
                return <RenderHomePage text={'PWA'} img={pwa}/>
    
            case(types.three):
                return <RenderHomePage text={store ? 'web sites' : 'sitios web'} img={webSite}/>
    
            case(types.four):
                return <RenderHomePage text={'ux ui design'} img={uxuiDesign}/>
        
            default: 
                return state;
        }
    }
    
    const [state, dispatch] = useReducer(reducer,initialState);

    useEffect(() => {
        if(page === 1) {
            dispatch({type: types.one})
        } else if(page === 2) {
            dispatch({type: types.two})
        } else if(page === 3) {
            dispatch({type: types.three})
        } else if(page === 4) {
            dispatch({type: types.four})
        }
    }, [page, types])
    
    return (
        <div className={styles.root}>
            <div className={styles.rootBackground}></div>
            <SocialNetworks/>
            <Header 
                methodology={methodology} 
                weAre={weAre}
                weMake={weMake}
                contact={contact}
                theSayAboutUs={theSayAboutUs}
                projects={projects}
                customer={customer}
            />
            <Pagination
                dispatch={dispatch} 
                types={types} 
                page={page}
                setPage={setPage}
            />
            {state}
            <div ref={weAre}>
                <AboutAs/>
            </div>
            <Hubs/>
            <Info/>
            <WorkProcess/>
            <Technologies/>
            <div ref={weMake}>
                <Packs/>
            </div>
            <div ref={theSayAboutUs}>
                <Opinions/>
            </div>
            <div ref={projects}>
                <OurProjects/>
            </div>
            <div ref={customer}>
                <CustomerLogos/>
            </div>
            <div ref={methodology}>
                <Methodology/>
            </div>
            <ProjectProcess/>
            <WorkTogether/>
            <div className={styles.contactFormAndUbication} ref={contact}>
                <div className={styles.contactFormRoot}>
                    <ContactForm/>
                </div>
                <Ubication/>    
            </div>
            <Footer
                methodology={methodology} 
                weAre={weAre}
                weMake={weMake}
                contact={contact}
                theSayAboutUs={theSayAboutUs}
                projects={projects}
                customer={customer}
            />
        </div>
    )
}

export default HomePage
