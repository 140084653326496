import React, { useRef, useEffect } from 'react';
import styles from './pagination.module.css';

const Pagination = ({dispatch, types, page, setPage}) => {
    const pages = [
        {number: 1, option: types.one},
        {number: 2, option: types.two},
        {number: 3, option: types.three},
        {number: 4, option: types.four}
    ];

    const browserLanguage = navigator.language || navigator.userLanguage; 

    const delay = 4000;
    const timeoutRef = useRef(null);

    const resetTimeout = ()=> {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setPage((prevIndex) =>
                    prevIndex === pages.length ? 0 : prevIndex + 1
                ),
            delay
        );
    
        return () => {
            resetTimeout();
        };
        // eslint-disable-next-line
    }, [page]);

    const handleNext = ()=> {
        if(page < 4) {
            setPage(page + 1)
        } else {
            setPage(1)
        }
    }

    const handlePrev = ()=> {
        if (page === 1) {
            setPage(4)
        } else {
            setPage(page - 1)
        } 
    }
    
    return (
        <div className={styles.root}>
            <button className={styles.decrement} onClick={handlePrev}>
               {(browserLanguage.includes("en") || browserLanguage.includes("es")) && "prev"}
            </button>
            <div className={styles.numbers}>
            {
                pages.map((page)=> {
                    return (
                        <button 
                            key={page.number} 
                            onClick={()=> dispatch({type: page.option})}
                            className={styles.button}
                            >
                          0{page.number}
                        </button>
                    )
                })
            }
            </div>
            <button className={styles.increment} onClick={handleNext}>
                {(browserLanguage.includes("en") || browserLanguage.includes("es")) && "next"}
            </button>
        </div>
    )
}

export default Pagination
