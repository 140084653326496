import React from 'react'
import Landing from '../landing';
import Ubication from '../../Ubication/ubication';
import WorkTogether from '../../WorkTogether/workTogether';
import OurProjects from '../../OurProjects/ourProjects';
import styles from './exoPage.module.css';
import { useSampleData } from './sampleData';
import ContactForm from '../../ContactForm/contactForm';
import Footer from '../../Footer/footer';

const ExoPage = () => {
    const { 
        technology,
        buttons,
        storeExo,
        textMethodology,
        methodology,
        otherProjectsText,
        otherProjects,
        exo 
    } = useSampleData();

    return (
        <div>
            <Landing 
                item={technology}
                buttons={buttons}
                store={storeExo}
                methodology={methodology}
                textMethodology={textMethodology}
                otherProjects={otherProjects}
                otherProjectsText={otherProjectsText}
                exo={exo}
            />
            <div className={styles.root}>
                <OurProjects landing={true}/>
                <WorkTogether/>
                <div className={styles.contactFormAndUbication}>
                    <div className={styles.contactFormRoot}>
                        <ContactForm/>
                    </div>
                    <Ubication/>    
                </div> 
               <Footer/>
            </div>
        </div>
    )
}

export default ExoPage