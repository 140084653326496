import React from 'react';
import styles from './socialNetworks.module.css';
import facebook from '../../../../assets/blueFacebook.png'; 
import linkedin from '../../../../assets/blueLinkedin.png';
import instagram from '../../../../assets/blueInstagram.png';

const SocialNetworks = () => {
    const data = [
        {
            id: "facebook",
            url: "https://www.facebook.com/helloiurco/",
            image: facebook
        },
        {
            id: "linkedin",
            url: "https://www.linkedin.com/company/iurco",
            image: linkedin
        },
        {
            id: "instagram",
            url: "https://www.instagram.com/helloiurco/",
            image: instagram
        }
    ];

    const email = "info@iurco.com";
    
    return (
        <div className={styles.root}>
            <h3 className={styles.title}>follow</h3>
            <div className={styles.info}>
                <p className={styles.email}>
                    <a href={`mailto: ${email}`}>{email}</a>
                </p>
                <div className={styles.socialNetworksRoot}>
                {
                    data.map((socialNetwork)=> 
                        <a  href={socialNetwork.url} key={socialNetwork.id} rel="noreferrer" target='_blank'>
                            <img 
                                src={socialNetwork.image} 
                                alt={socialNetwork.id}
                                className={styles.image}
                            />
                        </a>
                    )
                }
                </div>
            </div>
        </div>
    )
}

export default SocialNetworks;
