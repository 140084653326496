import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './sliderWorkProcess.module.css';
import { useSampleData } from '../sampleData';

const SliderWorkProcess = () => {
    const { items, textButton } = useSampleData();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const scrollToSection = () => {
        window.scrollTo({
            top: 8100,
            behavior: 'smooth',
        })
    };

    return (
        <div className={styles.root}>
            <Slider {...settings}>
                {items.map((item, index) => {
                    return (
                        <div  className={styles.slide} key={index}>
                            <h3 className={styles.title}>{item.title}</h3>
                            <ul>
                                {
                                    item.description_list.map((description, index) => {
                                        return (
                                            <div key={index} className={styles.item}>
                                                <span className={styles.icon}>+</span>
                                                <li className={styles.li}>
                                                    {description}
                                                </li>
                                            </div>
                                        )
                                    })
                                }
                            </ul>
                            <button onClick={scrollToSection} className={styles.button} type="button">
                                {textButton}
                            </button>
                            <img 
                                src={item.img} 
                                alt="icon" 
                                height={95} 
                                width={95}
                                className={styles.img}
                            />
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default SliderWorkProcess
