import magentoBusinessImg from '../../assets/logoMagentoBusiness.svg';
import vtexPartnerImg from '../../assets/logoVtexPartner.svg';
import pwaImg from '../../assets/logoPwa.svg';
import reactJsImg from '../../assets/logoReactJS.svg';
import nodeJsImg from '../../assets/logoNodeJS.svg';
import wordPressImg from '../../assets/logoWordPress.svg';
import drupalImg from '../../assets/logoDrupal.svg';

const items = [
    {
        img: magentoBusinessImg
    },
    {
        img: vtexPartnerImg
    },
    {       
        img: pwaImg
    },
    {  
        img: reactJsImg
    },
    {  
        img: nodeJsImg
    },
    {       
        img: wordPressImg
    },
    {       
        img: drupalImg
    },
]

export { items }