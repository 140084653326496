import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Landing from '../../landing';
import Ubication from '../../../Ubication/ubication';
import WorkTogether from '../../../WorkTogether/workTogether';
import OurProjects from '../../../OurProjects/ourProjects';
import styles from './newsanStore.module.css';
import { useSampleData } from './sampleData';
import ContactForm from '../../../ContactForm/contactForm';
import Footer from '../../../Footer/footer';

const StoreNewsanPage = () => {
    const { 
        technology,
        buttons,
        storeNewsanPage,
        textMethodology,
        methodology,
        otherProjectsText,
        otherProjects,
        imgBannerDesktop,
        storeNewsan 
    } = useSampleData();

    return (

        <div>
            <Landing 
                item={technology}
                buttons={buttons}
                store={storeNewsanPage}
                methodology={methodology}
                textMethodology={textMethodology}
                otherProjects={otherProjects}
                otherProjectsText={otherProjectsText}
                imgBannerDesktop={imgBannerDesktop}
                storeNewsan={storeNewsan}
            />
            <div className={styles.root}>
                <OurProjects landing={true}/>
                <WorkTogether/>
                <div className={styles.contactFormAndUbication}>
                    <div className={styles.contactFormRoot}>
                        <ContactForm/>
                    </div>
                    <Ubication/>    
                </div>  
                <Footer/>
            </div>
        </div>
    )
}

export default StoreNewsanPage