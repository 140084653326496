import React from 'react';
import styles from './workProcess.module.css';
import SliderWorkProcess from './SliderWorkProcess/sliderWorkProcess';
import vectorButton from '../../assets/vectorButton.svg';
import { useSampleData } from './sampleData';

const WorkProcess = () => {
    const { items, textButton } = useSampleData();

    const scrollToSection = () => {
        window.scrollTo({
            top: 100000,
            behavior: 'smooth',
        })
    };

    return (
        <div>
            <SliderWorkProcess/>
            <div className={styles.root}> 
                {items.map((item, index) => {
                    return (
                        <div className={styles.slide} key={index}>
                            <div className={styles.container}>
                                <div className={styles.contentContainer}>
                                    <h3 className={styles.title}>{item.title}</h3>
                                    <ul className={styles.list}>
                                        {
                                            item.description_list.map((description, index) => {
                                                return (
                                                    <div key={index} className={styles.item}>
                                                        <span className={styles.icon}>+</span>
                                                        <li className={(description === 'Creativity and Development' || description === 'Creatividad y desarrollo'  || description ==="Desarrollos de apps PWA" || description ==="PWA App Developments") ? styles.specialLi : styles.li}>
                                                            {description}
                                                        </li>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <img 
                                    className={
                                        (item.title ==="Consultancy" || item.title ==="Consultoría")
                                        ? styles.imgConsultancy
                                        : (item.title ==="Diseño ui/ux" || item.title ==="UI/UX Design")
                                        ? styles.imgDesign
                                        : styles.img
                                    }
                                    src={item.img} alt="icon"
                                />
                            </div>
                            <div className={styles.textAndButton}>
                                <button onClick={scrollToSection} className={styles.button} type="button">
                                    <div className={styles.buttonAndImage}>
                                        {textButton}  
                                        <img 
                                            className={styles.vectorImg}
                                            src={vectorButton} 
                                            alt="icon" 
                                            height={15} 
                                            width={15}
                                        />
                                    </div>
                                </button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default WorkProcess
