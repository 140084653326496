import React from 'react'
import { items } from './sampleData';
import styles from './technologies.module.css';
import useViewportWidth from '../../talons/ViewportWidth/useViewportWidth';
import SliderTechnologies from './SliderTechnologies/sliderTechnologies';

const Technologies = () => {
  const width = useViewportWidth();

    if(width < 800) {
      return <SliderTechnologies/>
    }

    return (
          <div className={styles.root}>
              {items.map((item, index) => {
                return (
                  <div className={styles.container} key={index}>
                    <img 
                      src={item.img} 
                      alt="logo" 
                      height={160}
                      width={160}
                     />
                  </div>
                )
             })}
          </div>
    )
}

export default Technologies;