import React from 'react';
import styles from './title.module.css';
import { useContext } from 'react'; 
import { StoreContext } from '../../Store/storeProvider';

const Title = ({text}) => {
    const [ store ] = useContext(StoreContext);

    return (
        <div className={styles.root}>
            <p className={styles.subTitle}>{store ? 'boutique agency' : 'agencia boutique'}</p>
            <p className={styles.title}>
                {store ? 'We are specialists in' : 'Somos especialistas en'}
                <span>{text}</span>
            </p>  
        </div>
    )
}

export default Title
